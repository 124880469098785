<template>
    <router-link to="" class="card-buy td">
        <div>
            <slot name="icon"></slot>
        </div>
        <div class="ml-[12px] text-[13px] font-medium">
            <slot name="title"></slot>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "SaleButton"
}
</script>

<style scoped>

</style>