<template>
    <div class="max-w-[1364px] mx-auto xl:px-0 px-[40px] mt-[55px]">
        <div class="flex xl:flex-row flex-col">
            <div class="xl:w-[24.6%] w-full">
                <Heading>
                    <template #name>
                        {{ $t('system.category') }}
                    </template>
                </Heading>
                <div class="flex flex-col scroll-area">

                    <a href="javascript:void(0);" @click="chooseCategory(null)"
                       :class="activeCatId === null ? 'bg-[#ededfc]' : 'hover:bg-[#F7F7FF]'"
                       class="flex flex-row group relative items-center black-link justify-between border-b border-[#E0DBF9] py-[15px] px-[12px]"
                    >
                        <div class="text-[16px] font-medium font-Montserrat">
                            {{ $t('system.all_category') }}
                        </div>
                        <div v-if="activeCatId === null">
                            <img src="@/assets/chevron-right.svg" alt="">
                        </div>
                        <div v-else class="opacity-0 transition duration-200 group-hover:opacity-100">
                            <img src="@/assets/chevron-right.svg" alt="">
                        </div>
                    </a>

                    <a href="javascript:void(0);" v-for="(cat, index) in p_categories" :key="index"
                       @click="chooseCategory(cat)"
                       :class="activeCatId === cat.id ? 'bg-[#ededfc]' : 'hover:bg-[#F7F7FF]'"
                       class="flex flex-row group relative items-center black-link justify-between border-b border-[#E0DBF9] py-[15px] px-[12px]"
                    >
                        <div class="text-[16px] font-medium  font-Montserrat">
                            {{ cat.name }}
                        </div>
                        <div v-if="activeCatId === cat.id">
                            <img src="@/assets/chevron-right.svg" alt="">
                        </div>
                        <div v-else class="opacity-0 transition duration-200 group-hover:opacity-100">
                            <img src="@/assets/chevron-right.svg" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="xl:w-[75.4%] w-full xl:ml-[30px] xl:mt-0 mt-6">
                <Slideshow/>
            </div>
        </div>
    </div>
</template>

<script>
    import Heading from "@/components/blocks/Heading";
    import Slideshow from "@/components/blocks/Slideshow";
    import i18n from "@/boot/i18n";
    // import KategoriyaNavSkidka from "@/components/blocks/KategoriyaNavSkidka";
    import axios from "axios";
    import {apiUrls} from "@/utils/const/apiUrls";
    import mixin from "@/boot/boot-mixin";
    import {mapGetters} from "vuex";

    export default {
        name: `Kategoriya`,
        components: {Slideshow, Heading},
        mixins: [mixin],
        data() {
            return {
                p_categories: [],
                activeCatId: null,
                name: '',
            }
        },
        methods: {
            ...mapGetters([
                'getLang'
            ]),

            getProductCategories() {
                axios.get(apiUrls.PRODUCT_CATEGORY + "?" + this.setParams({'language': this.getLang()}))
                    .then(res => {
                        this.p_categories = res.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            chooseCategory(val) {
                if (val) {
                    this.activeCatId = val.id;
                    this.$emit("category_id", val.id)
                    this.$emit("name", val.name)
                } else {
                    this.activeCatId = null;
                    this.$emit("category_id", null)
                    this.$emit("name", '')
                }
                this.jump();
            },
            // scroll down for Novinki component
            jump() {
                const element = document.getElementById('category-name');
                const yOffset = -100;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' })
            }
        },
        watch: {},
        mounted() {
            this.getProductCategories()
        }
    }
</script>

<style scoped>
    .scroll-area {
        overflow-y: auto;
        height: 400px;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #8764F8;
        border: none;
        width: 10px;
    }
</style>