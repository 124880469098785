<template>
    <div>
        <div class="max-w-[1364px] mx-auto mt-[53px] border border-[#E0DBF9] rounded-[17px]
                pt-[28px] pb-[61px] pl-[34px] pr-[40px]" v-if="!loader">
            <div class="flex flex-row">
                <div class="bg-[#F7F7FF] rounded-[13px] flex justify-center">
                    <div class="px-[70px] flex items-center">
                        <img v-if="product.images.length > 0" :src="product.images[0].image_url" :alt="product.name" :title="product.name" class="max-h-[250px]">
                        <img v-else :src="product?.image_url" alt="" class="max-h-[150px] py-[10px]">
                    </div>

                </div>
                <div class="ml-[38px] flex flex-col max-w-[868px]">
                    <ContentAbout :item="product">
                        <template #title>
                            {{ product?.name }}
                        </template>
                        <template #description>
                            {{ product?.description }}
                        </template>
                    </ContentAbout>
                </div>
            </div>
        </div>
        <div class="max-w-[1364px] mx-auto mt-[53px] border border-[#E0DBF9] rounded-[17px]
                pt-[28px] pb-[61px] pl-[34px] pr-[40px] flex justify-center mt-6 items-center w-full" v-else>
            <div style="color: #6848D2!important;" uk-spinner="ratio: 3"></div>
        </div>
    </div>
</template>

<script>

import ContentAbout from "@/components/blocks/ContentAbout";
import ImgAbout from "@/components/blocks/ImgAbout";
import axios from "axios";
import { apiUrls } from "@/utils/const/apiUrls";
import mixin from "@/boot/boot-mixin";

export default {
    name: `PodrobnaProducts`,
    components: { ContentAbout },
    mixins: [mixin],
    data() {
        return {
            product: {},
            images: [],
            id: this.$route.params,
            loader: true,
        }
    },
    methods: {
        init() {
            axios.get(apiUrls.PRODUCTS + '/with-images' + "?" + this.setParams({ 'id': this.$route.params?.id }))
                .then(res => {
                    this.product = res.data.data[0];
                    this.loader = false;
                })
        },
    },
    watch: {

        '$route': function (route) {
            if(route.params){
                this.init();
            }
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped></style>