<template>
  <div v-if="show" class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
    <div @click="close()" class="absolute w-full h-full bg-gray-900 bg-opacity-50 z-50">
      <div v-if="!openSms" class="absolute top-[200px] right-[250px] max-h-full min-w-[400px]">
        <div class="cursor-pointer">
          <img src="@/assets/close-karzinka.svg" class="uk-offcanvas-close" alt="close">
        </div>
        <div class="container bg-white overflow-hidden md:rounded-lg">
          <div @click.stop
            class="py-5 px-10 leading-none items-center font-medium text-sm bg-gray-100 border-b select-none">
            <div class="text-black font-bold text-center text-xl mb-8">{{ $t('system.enter_to_system') }}</div>
            <a :href="oneIDUri"
              class="flex flex-col justify-center text-black hover:no-underline mb-4 py-2 px-8 rounded-lg border border-[#6848D2] hover:bg-[#6848D2] hover:text-white">
              <div class="text-center font-medium text-base">
                {{ $t('system.oneid') }}
              </div>
            </a>
            <div @click="openSms = true"
              class="flex flex-col justify-center mb-4 py-2 px-8 text-black rounded-lg border border-[#6848D2] hover:bg-[#6848D2] hover:text-white cursor-pointer">
              <div class=" text-center font-medium text-base">
                {{ $t('system.sms') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="absolute h-full w-full flex items-center justify-center" @click.stop>
        <div class="bg-white max-h-full min-w-[450px] py-5 px-16 flex flex-col rounded-lg text-black font-medium absolute">
          <div class="cursor-pointer">
            <img src="@/assets/close-karzinka.svg" class="uk-offcanvas-close" alt="close" @click="close()">
          </div>
          <div class="mb-4 text-lg font-semibold text-center">
            {{ $t('system.sms') }}
          </div>
          <label class="my-2">
            {{ $t('system.phone') }}
            <div class="w-[402px] p-[18px] h-[56px] flex flex-row
                       border border-[#C3B2FF] rounded-[8px] relative">
              <div class="text-[#000] text-[16px] font-medium">
                (+998)
              </div>
              <div class="ml-[3px]">
                <input ref="phone" v-model="phone" placeholder="-- --- -- --" type="text" required maxlength="9"
                  class="focus:outline-0 w-full text-[#000] font-medium">
              </div>
            </div>
          </label>
          <label v-if="uuid" class="my-2">
            {{ $t('system.code') }}
            <div class="w-[402px] p-[18px] h-[56px] flex flex-row
                       border border-[#C3B2FF] rounded-[8px]">
              <input v-model="codeLogin" class="focus:outline-0 w-full text-[#000] font-medium mr-[180px]" type="text"
                name="codeLogin" id="codeLogin" placeholder="......">
            </div>
          </label>
          <div class="p-6">
            <button v-if="!uuid" @click="getCode()" class="block mx-auto py-2 px-12 rounded-lg bg-[#6848D2] text-white"
              type="button">{{ $t('system.codeLogin') }}</button>
            <button v-else @click="sendCode()" class="block mx-auto py-2 px-12 rounded-lg bg-[#6848D2] text-white"
              type="button">{{ $t('system.enter') }}</button>
          </div>
        </div>
      </div>
    </div>
    <vue-basic-alert :duration="300" :closeIn="3000" ref="alert" />
  </div>
</template>

<script>
import axios from "axios";
import { apiUrls } from '@/utils/const/apiUrls'
import mixin from "@/boot/boot-mixin";
import VueBasicAlert from 'vue-basic-alert'

export default {
  name: "RegistrationDialog",
  components: {VueBasicAlert},
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['dialogAction'],
  data() {
    return {
      openSms: false,
      phone: "",
      uuid: "",
      codeLogin: "",
      clientId: 'uzkarta_uz',
      clientSecret: '83nUdQc26UW4ZhIoiahG5QOE',
      state: 'eyJtZXRob2QiOiJJRFBXIn0=',
      oneIdUri: 'https://sso.egov.uz/sso/oauth/Authorization.do',
      code: window.location.search ? window.location.search.replace('?code=', '') : '',
      redirect_uri: location.origin.includes('localhost') ? 'http://localhost:8080/' : 'https://uzkarta.uz/',
    }
  },
  computed: {
    oneIDUri() {
      return this.oneIdUri + '?response_type=one_code&client_id=' + this.clientId
        + '&redirect_uri=' + this.redirect_uri + '&scope=' + this.clientId + '&state=' + this.state
    },
  },
  methods: {
    close() {
      this.$emit('dialogAction');
      this.openSms = false;
    },
    getCode() {
      if (this.phone.length == 9) {
        axios.post(apiUrls.SEND_CONFIRM_CODE + '?phone=' + this.phone)
          .then((res) => {
            this.uuid = res.data.uuid;
          })
          .catch((err) => {
            console.log(err);
          })
      }
      else{
        this.showErrorInfo('Telefon raqamingizni kiriting!');
      }
    },
    sendCode() {
      if (this.uuid && this.codeLogin) {
        axios.post(apiUrls.CHECK_CONFIRM_CODE + '?uuid=' + this.uuid + '&code=' + this.codeLogin)
          .then((res) => {
            this.$store.dispatch("login", res.data).then(() => {
              window.location.href = window.location.origin + '/#/cabinet';
            }
            )
          })
          .catch((err) => {
            this.showErrorInfo(err.response.data.message);
            console.log(err);
          })
      }
    }
  }
}
</script>

<style scoped></style>