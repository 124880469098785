<template>
    <div class="uk-flex border-b border-[#E7E2F9]">
        <div class="w-[254px] table-header">
            <slot name="h1"></slot>
        </div>
        <div class="w-[375px] table-header">
            <slot name="h2"></slot>
        </div>
        <div class="w-[208px] table-header">
            <slot name="h3"></slot>
        </div>
        <div class="w-[214px] table-header">
            <slot name="h4"></slot>
        </div>
        <div class="w-[218px] table-header">
            <slot name="h5"></slot>
        </div>
        <div class="w-[98px] table-header">
            <slot name="h6"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "TableBlock"
}
</script>

<style scoped>

</style>