import createPersistedState from "vuex-persistedstate";
import {createStore} from "vuex";
import {toNumber} from "uikit/src/js/util";

const store = createStore({
    state() {
        return {
            onStoreItems: [],
            onStoreCards: [],
            auth: {},
            cur_lang: 'uz'
        }
    },
    mutations: {
        setLang(state, language) {
            state.cur_lang = language
        },

        addStore(state, item) {
            const foundItem = state.onStoreItems.find(obj => obj.id === item.id);
            if (!foundItem) {
                item.count ? item.count : item["count"] = 1;
                state.onStoreItems.push(item);
            } else {
                foundItem.count = toNumber(foundItem.count) + 1;
            }
        },

        removeStore(state, item) {
            if (state.onStoreItems?.includes(item)) {
                state.onStoreItems = state.onStoreItems?.filter(obj => obj !== item)
            }
        },
        setCountStore(state, item) {
            if (state.onStoreItems?.includes(item)) {
                state.onStoreItems.count = item.count
            }
        },
        clearStoreItems(state, data) {
            state.onStoreItems = []
        },
        minusCountStore(state, item) {
            if (state.onStoreItems.find(obj => obj === item).count > 1) {
                state.onStoreItems.find(obj => obj === item).count -= 1;
            }
        },
        login(state, item) {
            // if (state.auth?.includes(item))
            //     state.auth = {...item}
            // fixme yuqoridagi kodni tushunmadim menimcha sintaktik xatolikga yo'l qo'yilgan(?.)
            state.auth = item
        },
        logout(state) {
            state.auth = {};
            state.onStoreItems = []
        }
    },
    actions: {
        addStore(state, item) {
            state.commit("addStore", item)
        },
        removeStore(state, item) {
            state.commit("removeStore", item)
        },
        setCountStore(state, item) {
            state.commit("setCountStore", item)
        },
        login(state, item) {
            state.commit("login", item)
        },
        logout(state) {
            state.commit("logout")
        },

    },
    getters: {
        getUser(state) {
            return state.auth
        },
        getSIProducts(state) {
            return state.onStoreItems
        },
        getLang(state) {
            return state.cur_lang
        }
    },
    plugins: [createPersistedState()]
});

export default store;