<template>
    <div class="object-cover text-[#6C4BD8] text-[16px] font-medium
                bg-[#fff]  flex flex-row items-center ml-5
                border border-[#E0DBF9] overflow-hidden rounded-[7px] max-h-[41.5px] max-w-[144px]"
         style="width: 144px!important;">
        <div @click="removeLikes" style="min-width: 49px!important;min-height: 43px!important;"
             class=" cursor-pointer count-icon py-[21.5px] px-[17.2px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="16.688" height="2" viewBox="0 0 16.688 2">
                <line x2="14.688" transform="translate(1 1)" fill="currentColor" stroke="currentColor"
                      stroke-linecap="round"
                      stroke-width="2"/>
            </svg>
        </div>
        <div class="pro-cnt flex justify-center items-center text-[18px] font-semibold " style="width: 46px!important;">
<!--            {{ likes }}-->
                <input ref="input" @change="changeLikes($event)" class="w-full text-center uk-text-bold" type="number" :value="item.count" style="width: 35px;" data-hs-input-number-input/>
        </div>
        <div @click="addLikes" class=" cursor-pointer count-icon-plus
            py-[14.2px] pl-[17.7px] pr-[16.7px]" style="min-width: 49px!important;min-height: 43px!important;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16.688" height="16.688" viewBox="0 0 16.688 16.688">
                <g transform="translate(-1005.156 -537.156)">
                    <line x2="14.688" transform="translate(1006.156 545.5)" fill="currentColor" stroke="currentColor"
                          stroke-linecap="round" stroke-width="2"/>
                    <line x2="14.688" transform="translate(1013.5 538.156) rotate(90)" fill="currentColor"
                          stroke="currentColor"
                          stroke-linecap="round" stroke-width="2"/>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
    import {mapMutations} from "vuex";

    export default {
        name: `CountBtnV2`,
        props: {
            product: Object
        },
        data() {
            return {
                item: this.product,
                likes: this.product.count,
            }
        },
        methods: {
            ...mapMutations([
                'addStore', 'minusCountStore', 'setCountStore'
            ]),

            addLikes() {
                this.likes += 1;
                this.addStore(this.item)
                this.sayToParent();
            },
            changeLikes(val) {
                this.item.count = val.target.value
                this.setCountStore(this.item)
                this.sayToParent();
            },
            removeLikes() {
                if (this.item.count > 1) {
                    this.likes -= 1;
                    this.minusCountStore(this.item)
                    this.sayToParent();
                }
            },
            sayToParent() {
                this.$emit("sayToParent");
            },

            // addLikes() {
            //     this.likes += 1;
            //     this.emitToParent();
            // },
            // removeLikes() {
            //     if (this.likes > 1){
            //         this.likes -= 1;
            //         this.emitToParent();
            //     }
            // },

        }
    }
</script>

<style>
    .pro-cnt input[type='number'] {
        -moz-appearance:textfield;
    }
    .pro-cnt input::-webkit-outer-spin-button,
    .pro-cnt input::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    .count-icon {
        background-color: #F7F7FF;
        color: #6C4BD8 !important;
    }

    .count-icon:hover {
        color: #fff !important;
        background-color: #6C4BD8;
        transition: all 0.8s;
    }

    .count-icon-plus {
        background-color: #F7F7FF;
        color: #6C4BD8 !important;
    }

    .count-icon-plus:hover {
        color: #fff !important;
        background-color: #6C4BD8;
        transition: all 0.8s;
    }
</style>