<template>
    <div>
        <router-view/>
    </div>
    <!-- <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true"
         class="uk-overlay-primary uk-position-cover">
        <div class="uk-offcanvas-bar"
             style="padding: 0px!important; width: 504px!important;height: 100%!important;">
            <Karzinka/>
        </div>
    </div> -->
</template>
<script>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
// import Karzinka from "@/components/blocks/Karzinka";
// import OrderKarzinka from "@/components/blocks/OrderKarzinka";

UIkit.use(Icons);
export default {
    name: `App`,
    // components: {Karzinka}
}
</script>
<style>
@import url('./output.css');
@import url('~uikit/dist/css/uikit.min.css');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    font-family: 'Montserrat', sans-serif !important;
}

</style>
