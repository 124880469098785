import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from '../i18n'

// Vue.use(VueI18n)

const i18n = createI18n({
    locale: 'uz',
    fallbackLocale: 'ru',
    messages,
    silentTranslationWarn:true,
})
//



export default i18n
const app = createApp({
    // something vue options here ...
})

// app.mount('#app')
