<template>
    <div class="object-cover text-[#6C4BD8] text-[14px] font-medium
                bg-[#fff]  flex flex-row items-center
                border border-[#E0DBF9] overflow-hidden rounded-[7px] max-h-[29.44] max-w-[98.6]"
    >
        <div @click="removeLikes"
             style="min-width: 33.55px!important;min-height: 29.44px!important;"
             class=" cursor-pointer count-icon py-[14.7px] px-[11.7px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.057" height="2" viewBox="0 0 12.057 2">
                <line x2="10.057" transform="translate(1 1)" fill="currentColor" stroke="currentColor"
                      stroke-linecap="round"
                      stroke-width="2"/>
            </svg>
        </div>
        <div class="pro-cnt text-[14px] font-semibold flex justify-center items-center"
             style="width: 31.5px!important;">
<!--            {{ n_product.count }}-->
            <input ref="input" @change="changeLikes($event)" class="w-full text-center uk-text-bold" type="number" :value="n_product.count" style="width: 35px;" data-hs-input-number-input/>
        </div>
        <div @click="addLikes" class=" cursor-pointer count-icon-plus
            py-[9.7px] pl-[12.1px] pr-[11.4px]" style="min-width: 33.55px!important;min-height: 29.44px!important;">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.057" height="12.057" viewBox="0 0 12.057 12.057">
                <g transform="translate(1 1)">
                    <line x2="10.057" transform="translate(0 5.029)" fill="currentColor" stroke="currentColor"
                          stroke-linecap="round"
                          stroke-width="2"/>
                    <line x2="10.057" transform="translate(5.029 0) rotate(90)" fill="currentColor"
                          stroke="currentColor"
                          stroke-linecap="round" stroke-width="2"/>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        name: "SmallCountBtn",
        props: {
            product: Object
        },
        data() {
            return {
                // eslint-disable-next-line vue/no-reserved-keys
                n_product: this.product

            }
        },
        methods: {
            ...mapMutations([
                'addStore', 'minusCountStore', 'setCountStore'
            ]),
            addLikes() {
                this.addStore(this.n_product)
            },

            changeLikes(val) {
                this.n_product.count = val.target.value
                this.setCountStore(this.n_product)
            },

            removeLikes() {
                if (this.n_product.count > 1) {
                    this.minusCountStore(this.n_product)
                }
            }
        }
    }
</script>

<style scoped>

    .pro-cnt input[type='number'] {
        -moz-appearance:textfield;
    }
    .pro-cnt input::-webkit-outer-spin-button,
    .pro-cnt input::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    .count-icon {
        background-color: #F7F7FF;
        color: #6C4BD8 !important;
    }

    .count-icon:hover {
        color: #fff !important;
        background-color: #6C4BD8;
        transition: all 0.8s;
    }

    .count-icon-plus {
        background-color: #F7F7FF;
        color: #6C4BD8 !important;
    }

    .count-icon-plus:hover {
        color: #fff !important;
        background-color: #6C4BD8;
        transition: all 0.8s;
    }
</style>