<template>
    <div class="max-w-[1364px] mx-auto mt-[29px]" >
        <div class="flex flex-row items-center">
            <div>
                <a href="javascript:void(0);" class="text-[15px] font-medium text-[#989898] td
                            hover:text-[#989898]"
                >
                    {{ $t('system.bosh_sahifa') }}
                </a>
            </div>
            <div class="flex items-center mx-[13px]">
                <img src="@/assets/chevron-right-gray.svg" alt="">
            </div>
            <div>
                <div class="text-[15px] text-[#000] font-medium td">
                    {{ $t('system.buyurtma_berish') }}
                </div>
            </div>
        </div>

        <IzbranniyLayout/>

        <div class="text-[25px] font-semibold text-[#000]
                    pb-[17.7px] header-underline-xl mb-[50px]"
        >
            {{ $t('system.yaqinda_korilgan_mahsulot') }}
        </div>

        <NedovnaCard/>

    </div>
</template>

<script>
import NedovnaCard from "@/components/blocks/NedovnaCard";
import IzbranniyLayout from "@/components/layouts/IzbranniyLayout";
export default {
    name: `Izbranniy`,
    components: {IzbranniyLayout, NedovnaCard,},
}
</script>

<style scoped>

</style>