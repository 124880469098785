<template>
    <router-link to="" class="uk-inline td">
        <div class="border border-[#C3B2FF] bg-[#fff] hover:bg-[#F7F7FF]
                hover:border-[#6848D2] rounded-[8px] w-[490px] h-[56px]
                uk-flex uk-flex-between items-center px-[24px]"
             style="transition: all .3s"
        >
            <slot>

            </slot>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "InputPayment"
}
</script>

<style scoped>

</style>