export const baseUrl =
    {
        // url: location.origin.includes('localhost') ? 'http://localhost:8083/api' : 'http://uzkarta.uz/api',
        url: location.origin.includes('localhost') ? 'https://uzkarta.uz/api' : 'https://uzkarta.uz/api',
        timeout: 60 * 60 * 20,
        UPLOAD_TIMEOUT: 2 * 60 * 1000,
    }
export const apiUrls = {
    //base
    USER_TYPES: baseUrl.url + '/user-types',
    USERS: baseUrl.url + '/users',
    MODULES: baseUrl.url + '/modules',
    AUTH: baseUrl.url + '/auth',
    SESSIONS: baseUrl.url + '/sessions',
    APP_ACTIONS_GROUP: baseUrl.url + '/app-action-groups',
    APP_ACTIONS: baseUrl.url + '/app-actions',
    USER_ROLES: baseUrl.url + '/user-roles',
    REGIONS: baseUrl.url + '/regions',
    AREAS: baseUrl.url + '/areas',
    APP_ACCESS: baseUrl.url + '/app-access',
    BLOG_AND_NEWS: baseUrl.url + '/blog-and-news',
    BLOG_AND_NEWS_COMMENTS: baseUrl.url + '/blog-and-news-comments',
    SYSTEM_SETTINGS: baseUrl.url + '/system-settings',
    SYSTEM_SETTING_TYPES: baseUrl.url + '/system-settings-types',
    SEND_CONFIRM_CODE: baseUrl.url + '/notifier/send-confirm-code',
    CHECK_CONFIRM_CODE: baseUrl.url + '/notifier/check-confirm-code',

    //file reource
    FILES: baseUrl.url + '/file',
    UPLOAD_RESOURCE: baseUrl.url + "/resources/upload",
    RESOURCE: baseUrl.url + "/resources",
    RESOURCE_VIEW: baseUrl.url + "/admin/resources-view",

    //esavdo
    PUBLICATIONS: baseUrl.url + '/publications',
    PUBLICATION_TYPES: baseUrl.url + '/publication-types',
    PRODUCT_CATEGORY: baseUrl.url + '/product-category',
    PRODUCTS: baseUrl.url + '/products',
    SLIDER_CONTENT: baseUrl.url + '/slider-content',
    PRODUCT_IMAGES: baseUrl.url + '/product-images',
    WAREHOUSE: baseUrl.url + '/warehouse',
    WAREHOUSE_LOG: baseUrl.url + '/warehouse-log',
    PRODUCT_AGREEMENTS: baseUrl.url + '/agreements/product-agreements',
    AGREEMENTS: baseUrl.url + '/agreements',
    AGREEMENTS_POST: baseUrl.url + '/agreements/add-product-agreements',
    ONE_ID_OAUTH2: baseUrl.url + '/oauth-one-id',
    BTS_REGIONS: baseUrl.url + '/bts-region',
    BTS_AREAS: baseUrl.url + '/bts-area',
    BTS: baseUrl.url + '/bts',
}



