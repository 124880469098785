<template>
    <div class="uk-flex border-b border-[#E7E2F9] h-[84.4px]">
        <div class="w-[254px] table-text">
            <slot name="t1"></slot>
        </div>
        <div class="w-[375px] table-text">
            <div class="w-[258px]">
                <slot name="t2" class=""></slot>
            </div>
        </div>
        <div class="w-[208px] table-text">
            <slot name="t3"></slot>
        </div>
        <div class="w-[214px] table-text pl-[51px]">
            <slot name="t4"></slot>
        </div>
        <div class="w-[218px] table-text">
            <slot name="t5"></slot>
        </div>
        <div class="w-[98px] table-sale">
            <slot name="t6"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "TableText"
}
</script>

<style scoped>

</style>