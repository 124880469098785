<template>
    <div class="flex flex-row justify-between border-b border-[#E0DBF9] pb-[13.7px]">
        <div class="flex flex-row">
            <div class="text-[#000] text-[20px] font-bold mr-[60px]">
                <slot name="title"></slot>
            </div>
            <div>
                <a class="td flex items-center" @click="share">
                    <img src="@/assets/social-icon.svg" class="inline" alt="">
                    <span class="text-[15px] font-medium text-[#6848D2] ml-[14.4px]">{{$t('system.ulashing')}}</span>
                </a>
            </div>
        </div>
<!--        <div class="text-[#000] text-[16px] font-medium">-->
<!--            &lt;!&ndash;            Купили более 2 200 раз&ndash;&gt;-->
<!--        </div>-->
    </div>
    <div class="py-[24px] text-[16px] text-[#000] font-regular">
        <slot name="description"></slot>
    </div>
    <div class="flex flex-row ">
        <div class="mr-[32px] text-[24px] font-semibold text-[#6C4BD8]">
            {{number_format(item.selling_price, ' ', ' ') + ' ' + $t('system.uz_sum')}}
        </div>
    </div>
    <div class="flex flex-row pt-[19px] pb-[25px] text-[17px] text-[#000]">
        <div>
            {{$t('system.sotuvda')}}:
        </div>
        <div class="font-bold ml-[4px]">
            {{$t('system.mavjud')}} {{' (' + item.balance + ')'}}
        </div>
    </div>
    <div class="flex flex-row ">
        <div>
            <SaleButton class="text-[15px] font-medium" @click="toBasket">
                <template #title>
                    {{$t('system.savatchaga')}}
                </template>
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.648" height="19.163"
                         viewBox="0 0 22.648 19.163">
                        <g transform="translate(0)">
                            <g transform="translate(0 0)">
                                <g transform="translate(0 0)">
                                    <path
                                            d="M5.431,5.323H17.162c.966,0,1.961-.024,2.923,0,1.265.029.823,1.167.688,1.841-.2,1-.515,2.769-.736,3.669a.791.791,0,0,1-.864.62H6.628a4.394,4.394,0,0,0-.132-.778l-.152-.758c-.176-.881-.831-3.945-.914-4.593ZM-.123.631A.761.761,0,0,0,.688,1.5c.495,0,.993,0,1.488,0,.535,0,.833.1.987.5.107.279,2.2,10.991,2.511,12.524a3.408,3.408,0,0,0,.363,1.169,2.278,2.278,0,0,0,1.883,1.133h11.97a.777.777,0,0,0,.259-1.456,19.575,19.575,0,0,0-3.036-.076H8.83c-1.6,0-1.439-.1-1.7-1.409a6.337,6.337,0,0,1-.164-.89H19.412a2.2,2.2,0,0,0,1.641-.8,3.375,3.375,0,0,0,.669-1.821c.154-.77.8-3.63.8-4.369a2.283,2.283,0,0,0-2.2-2.2H5.1a3.252,3.252,0,0,0-.108-.658c-.041-.2-.088-.45-.135-.679A5.21,5.21,0,0,0,4.506,1.22,2.268,2.268,0,0,0,2.558-.04H.547a.788.788,0,0,0-.67.67Z"
                                            transform="translate(0.123 0.04)" fill="currentColor"
                                    />
                                    <path
                                            d="M13760.9,13761.591c0,.529.322.862.669.862h10.152c.349,0,.67-.333.67-.862a.788.788,0,0,0-.67-.671h-10.152A.787.787,0,0,0,13760.9,13761.591Z"
                                            transform="translate(-13753.239 -13753.259)" fill="currentColor"
                                    />
                                    <path
                                            d="M15137,31650.691a.772.772,0,1,0,1.532,0c0-.348-.331-.672-.862-.672A.787.787,0,0,0,15137,31650.691Z"
                                            transform="translate(-15128.573 -31632.398)" fill="currentColor"
                                    />
                                    <path
                                            d="M31650.1,31650.691a.773.773,0,1,0,1.533,0c0-.348-.332-.672-.861-.672A.789.789,0,0,0,31650.1,31650.691Z"
                                            transform="translate(-31632.477 -31632.398)" fill="currentColor"
                                            fill-rule="evenodd"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </template>
            </SaleButton>
        </div>
        <div class="">
            <CountBtn @childToParent="updateLikes" :value="1"/>
        </div>
    </div>

    <vue-basic-alert
            :duration="300"
            :closeIn="3000"
            ref="alert"/>
</template>

<script>
    import SaleButton from "@/components/blocks/SaleButton";
    import CountBtn from "@/components/blocks/CountBtn";
    import {mapMutations} from "vuex";
    import mixin from "@/boot/boot-mixin";
    import VueBasicAlert from 'vue-basic-alert'

    export default {
        name: "ContentAbout",
        components: {CountBtn, SaleButton, VueBasicAlert},
        mixins: [mixin],
        props: {
            item: Object
        },
        data() {
            return {
                likes: 1
            }
        },
        methods: {
            ...mapMutations([
                'addStore'
            ]),
            share() {
                this.copyURL(window.location.href)
            },
            async copyURL(link) {
                try {
                    await navigator.clipboard.writeText(link);
                    this.$refs.alert
                        .showAlert(
                            'success',// There are 4 types of alert: success, info, warning, error
                        this.$t('system.copied_message'),// Message of the alert
                            this.$t('system.copied'), // Alert header
                            {
                                iconSize: 35, // Size of the icon (px)
                                iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                                position: 'top right'
                            },
                        )
                    
                } catch ($e) {
                    this.$refs.alert
                        .showAlert(
                            'error',// There are 4 types of alert: success, info, warning, error
                            this.$t('system.copied_error_message'),// Message of the alert
                            this.$t('system.copied_error'), // Alert header
                            {
                                iconSize: 35, // Size of the icon (px)
                                iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                                position: 'top right'
                            },
                        )
                }
            },
            updateLikes(data) {
                this.likes = data;
            },
            toBasket() {
                let data = this.item
                data["count"] = this.likes;
                if (this.likes > this.item.balance) {
                    this.showWarningInfo('Hozirda ushbu mahsulotdan omborda qolmagan!');
                    return;
                }
                this.addStore(data)
            }
        }
    }
</script>

<style scoped>

</style>