import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {Vuelidate} from "vuelidate";
import VueMask from '@devindex/vue-mask';
import store from './store'
import i18n from './boot/i18n'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

createApp(App)
    .use(router)
    .use(VueMask)
    .use(Vuelidate)
    .use(store)
    .use(i18n)
    .use(Datepicker).mount('#app')
