<template>
    <div class="max-w-[1920px] mx-auto overflow-hidden">
        <div class="bg-[#F7F7FF]">
            <div class="max-w-[1364px] mx-auto xl:px-0 px-[40px]">
                <div class="flex flex-row py-[30px]">
                    <router-link to="" class="flex flex-row  items-center td">
                        <div class="bg-[#fff] rounded-[15px] p-[16px] ">
                            <img src="@/assets/kub-icon.svg" alt="">
                        </div>
                        <div class="ml-[20px]">
                            <div class="text-[18px] text-[#000] font-bold">
                                {{ $t('system.bozorga_borishni_hojati_yoq') }}
                            </div>
                            <div class="text-[#000] text-[17px]">
                                {{ $t('system.qulay_narxda_yetkazib') }}
                            </div>
                        </div>
                    </router-link>
                    <router-link to="" class="flex flex-row  ml-[90px] items-center td">
                        <div class="bg-[#fff] rounded-[15px] py-[24px] px-[14px]">
                            <img src="@/assets/dastavka-blue.svg" alt="">
                        </div>
                        <div class="ml-[34px]">
                            <div class="text-[18px] text-[#000] font-bold">
                                {{ $t('system.tez_yetkazish') }}
                            </div>
                            <div class="text-[#000] text-[17px]">
                                {{ $t('system.qoyil_qolasiz') }}
                            </div>
                        </div>
                    </router-link>
                    <!--                <router-link to="" class="flex flex-row ml-[80px] items-center td">-->
                    <!--                    <div class="bg-[#fff] rounded-[15px] py-[24px] px-[14px]">-->
                    <!--                        <img src="@/assets/dastavka-blue.svg" alt="">-->
                    <!--                    </div>-->
                    <!--                    <div class="ml-[34px]">-->
                    <!--                        <div class="text-[18px] text-[#000] font-bold">-->
                    <!--                            Быстрая доставка-->
                    <!--                        </div>-->
                    <!--                        <div class="text-[#000] text-[17px]">-->
                    <!--                            Наш сервис удивит вас-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <!--                </router-link>-->
                </div>
            </div>
        </div>
        <footer class="bg-[#6848D2]">
            <div class="max-w-[1364px] mx-auto  xl:px-0 px-[40px]">
                <div class="flex sm:flex-row flex-col  py-[45px]">
                    <div class="flex flex-col xl:w-[40%] md:w-[35%] w-full xl:pr-[60px] md:border-r
                        md:border-[#8060E8] border-none justify-center mx-auto"
                    >
                        <div class="flex items-center md:justify-start justify-center">
                            <div class="bg-[#fff] rounded-full p-[5px]">
                                <img src="@/assets/navbar-logo.svg" alt="">
                            </div>
                            <div class="ml-[20px]">
                                <div class="uppercase navbar-item">
                                    Kartografiya
                                </div>
                                <div class="navbar-item uppercase font-bold">
                                    IICHDK
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center mt-[25px]">
                            <div>
                                <img src="@/assets/telefon-icon.svg" alt="">
                            </div>
                            <div class="ml-[13px]">
                                <a href="tel:+998 71 262 53-66" class="footer-link">
                                    +998 71 262 - 53 - 66
                                </a>
                            </div>
                        </div>
                        <div class="flex items-center mt-[25px]">
                            <div>
                                <img src="@/assets/location-icon.svg" alt="">
                            </div>
                            <div class="ml-[13px]">
                                <a href="javascript:void(0)" class="footer-link">
                                    {{ $t('system.location') }}
                                </a>
                            </div>
                        </div>
                        <div class="flex items-center mt-[25px]">
                            <div>
                                <img src="@/assets/email-icon.svg" alt="">
                            </div>
                            <div class=" ml-[13px]">
                                <a href="mailto: info@kartografiya.uz" class="footer-link">
                                    info@kartografiya.uz
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3 pt-[35px]  xl:block hidden">
                        <div class="flex flex-col px-[60px]  border-r border-l border-[#8060E8]">
                            <div class="text-[#fff] text-[16px] font-medium mb-4">
                                {{ $t('system.address_in_map') }}
                            </div>

                            <yandex-map
                                    :settings="settings"
                                    :coords="coords"
                                    zoom="17"
                                    style="width: 330px; height: 200px;">

                                <ymap-marker
                                        :coords="coords"
                                        :balloon="{header: 'header', body: 'body', footer: 'footer'}"
                                        :icon="{color: 'green'}"
                                        cluster-name="1">

                                </ymap-marker>
                            </yandex-map>

<!--                            <div class="mb-[10px]">-->
<!--                                <a href="#" class="footer-link">-->
<!--                                    {{ $t('system.inform') }}-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="mb-[10px]">-->
<!--                                <a href="#" class="footer-link">-->
<!--                                    {{ $t('system.deliver_inform') }}-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="mb-[10px]">-->
<!--                                <a href="#" class="footer-link">-->
<!--                                    {{ $t('system.back_product') }}-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <a href="#" class="footer-link">-->
<!--                                    {{ $t('system.about') }}-->
<!--                                </a>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="pt-[35px] xl:w-1/3 md:w-[50%] w-full">
                        <div class="flex flex-col md:px-[60px] px-0">
                            <div class="text-[#fff] text-[16px] font-medium">
                                {{ $t('system.messenger') }}
                            </div>
                            <div class="mt-[20px] md:pb-[64px] pb-[20px] flex flex-row">
                                <div>
                                    <a href="#">
                                        <div class=" hover:bg-[#fff] hover:text-[#6848d2] text-[#fff] border border-[#fff]
                                    rounded-full py-[11px] px-[10px] justify-center items-center"
                                             style="transition: all .8s">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.426" height="12.022"
                                                 viewBox="0 0 14.426 12.022">
                                                <path
                                                        d="M5.66,9.923l-.239,3.356a.834.834,0,0,0,.667-.323l1.6-1.53,3.317,2.429c.608.339,1.037.16,1.2-.56l2.177-10.2h0c.193-.9-.325-1.251-.918-1.03L.67,6.963C-.2,7.3-.191,7.789.521,8.01L3.793,9.027l7.6-4.755c.358-.237.683-.106.415.131Z"
                                                        transform="translate(0 -2)" fill="currentColor"/>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                                <div class="ml-[12px]">
                                    <a href="#">
                                        <div class=" hover:bg-[#fff] hover:text-[#6848d2] text-[#fff] border border-[#fff]
                                    rounded-full py-[8px] px-[12px] justify-center items-center"
                                             style="transition: all .8s">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8.724" height="16.709"
                                                 viewBox="0 0 8.724 16.709">
                                                <path
                                                        d="M13.318,2.774h1.593V.118A21.465,21.465,0,0,0,12.591,0a3.6,3.6,0,0,0-3.87,3.926v2.34H6.187v2.97H8.721v7.473h3.107V9.236H14.26l.386-2.97H11.827V4.22c0-.858.242-1.446,1.491-1.446Z"
                                                        transform="translate(-6.187)" fill="currentColor"/>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="footer-link">
                                {{ $t('system.tizim') }} <a href="#"
                                                            class="text-[16px] text-[#fff] white-link font-medium hover:no-underline">«Geoinfocom»</a>
                                {{ $t('system.tizim_a') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-[30px] pb-[20px] xl:hidden block">
                    <div id="app" class="flex flex-col">
                        <div class="text-[#fff] text-[16px] font-medium mb-4">
                            {{ $t('system.address_in_map') }}
                        </div>
                        <yandex-map
                                :settings="settings"
                                :coords="coords"
                                zoom="17"
                                style="width: 320px; height: 200px;">

                            <ymap-marker
                                    :coords="coords"
                                    :balloon="{header: 'header', body: 'body', footer: 'footer'}"
                                    :icon="{color: 'green'}"
                                    cluster-name="1">

                            </ymap-marker>
                        </yandex-map>

<!--                        <router-link to="" class="footer-link mb-[10px]">-->
<!--                            {{ $t('system.inform') }}-->
<!--                        </router-link>-->
<!--                        <router-link to="" class="footer-link mb-[10px]">-->
<!--                            {{ $t('system.deliver_inform') }}-->
<!--                        </router-link>-->
<!--                        <router-link to="" class="footer-link mb-[10px]">-->
<!--                            {{ $t('system.back_product') }}-->
<!--                        </router-link>-->
<!--                        <router-link to="" class="footer-link ">-->
<!--                            {{ $t('system.about') }}-->
<!--                        </router-link>-->
                    </div>
                </div>
            </div>
        </footer>
        <div class="bg-[#fff]">
            <div class="max-w-[1364px] mx-auto py-[20px] xl:px-0 px-[40px]">
                <div class="text-[16px] text-[#000] font-light">
                    &copy; {{ '2021-'+ currentYear }} {{ $t('system.back_footer') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import YmapPlugin from 'vue-yandex-maps'
    import {yandexMap, ymapMarker} from 'vue-yandex-maps'


    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Footer",
        components: {yandexMap, ymapMarker},

        data: () => ({
            currentYear: null,
            coords: [41.338604, 69.333447],
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: 'https://image.flaticon.com/icons/png/512/33/33447.png',
                imageSize: [43, 43],
                imageOffset: [0, 0],
                content: '123 v12',
                contentOffset: [0, 15],
                contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
            },
            settings: {
                apiKey: 'f5d3c0d0-8231-40b2-9d58-fee7829f02a6',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            }
        }),
        created() {
            this.currentYear = new Date().getFullYear();
        }
    }
</script>

<style scoped>
    #app {
        width: 100%;
        height: 100vh;
    }

    .ymap-container {
        height: 100%;
    }
</style>