<template>
    <div class="uk-position-relative bg-[#6848D2] rounded-[17px] linear-slideshow uk-visible-toggle uk-light uk-disabled"
         tabindex="-1" uk-slideshow="max-height: 450; autoplay:true; autoplay-interval: 6000; pause-on-hover: false;"  v-if="!loader">
        <ul class="uk-slideshow-items" style="max-height: 450px!important;">
            <li v-if="this.sliderItems.length > 0">
                <div class="uk-flex uk-flex-between flex-col md:flex-row md:mt-[99px] md:ml-[60px] sm:mt-[15px] mt-[30px]">
                    <div class="flex flex-col max-w-[412px] relative z-[9999999] md:mx-0 mx-auto">
                        <div class="md:text-[28px] lg:text-[30px] sm:text-[20px] text-[16px] text-[#fff] font-extrabold md:mx-0 mx-auto">
                            {{this.sliderItems.length > 0 ? this.sliderItems[0].title:''}}
                        </div>
                        <div class="text-[#fff] md:text-[16px] text-[14px] md:py-[19px] sm:py-[10px] py-[6px] md:mx-0 mx-auto">
                            {{this.sliderItems.length > 0 ? this.sliderItems[0].message:''}}
                        </div>
                    </div>
                    <div class="absolute slideshow">
                        <img class="images"
                             v-bind:src="this.sliderItems.length > 0 ? this.sliderItems[0].image_url:''"
                             alt=""
                        >
                    </div>
                </div>
            </li>
            <li v-if="this.sliderItems.length > 1">
                <div class="uk-flex uk-flex-between flex-col md:flex-row md:mt-[99px] md:ml-[60px] sm:mt-[15px] mt-[30px]">
                    <div class="flex flex-col max-w-[412px] relative z-[9999999]  md:mx-0 mx-auto">
                        <div class="md:text-[28px] lg:text-[30px] sm:text-[20px] text-[16px] text-[#fff] font-extrabold md:mx-0 mx-auto">
                            {{this.sliderItems.length > 1 ? this.sliderItems[1].title:''}}
                        </div>
                        <div class="text-[#fff] md:text-[16px] text-[14px] md:py-[19px] sm:py-[10px] py-[6px] md:mx-0 mx-auto text-center md:text-left">
                            {{this.sliderItems.length > 1 ? this.sliderItems[1].message:''}}
                        </div>

                    </div>
                    <div class="absolute slideshow">
                        <img class="images"
                             v-bind:src="this.sliderItems.length > 1 ? this.sliderItems[1].image_url:''"
                             alt=""
                        >
                    </div>
                </div>
            </li>
            <li v-if="this.sliderItems.length > 2">
                <div class="uk-flex uk-flex-between flex-col md:flex-row md:mt-[99px] md:ml-[60px] sm:mt-[15px] mt-[30px]">
                    <div class="flex flex-col max-w-[412px] relative z-[9999999] md:mx-0 mx-auto">
                        <div class="md:text-[28px] lg:text-[30px] sm:text-[20px] text-[16px] text-[#fff] font-extrabold md:mx-0 mx-auto">
                            {{this.sliderItems.length > 2 ? this.sliderItems[2].title:''}}
                        </div>
                        <div class="text-[#fff] md:text-[16px] text-[14px] md:py-[19px] sm:py-[10px] py-[6px] md:mx-0 mx-auto text-center md:text-left">
                            {{this.sliderItems.length > 2 ? this.sliderItems[2].message:''}}
                        </div>
                    </div>
                    <div class="absolute slideshow">
                        <img class="images"
                             v-bind:src="this.sliderItems.length > 2 ? this.sliderItems[2].image_url:''"
                             alt=""
                        >
                    </div>
                </div>
            </li>
        </ul>

        <ul class="uk-slideshow-nav uk-dotnav absolute top-[362px] left-[85px] " ></ul>

    </div>
</template>

<script>
    import {apiUrls} from "@/utils/const/apiUrls";
    import axios from "axios";
    import mixin from "@/boot/boot-mixin";
    import {mapGetters} from "vuex";

    export default {
        name: `Slideshow`,
        mixins: [mixin],
        data() {
            return {
                sliderItems: [],
                loader: true
            }
        },
        methods: {
            ...mapGetters([
                'getLang'
            ]),

            getSliderContent() {
                axios.get(apiUrls.SLIDER_CONTENT + "?" + this.setParams({'language': this.getLang()}))
                    .then(res => {
                        this.sliderItems = res.data.data;
                        this.loader = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        this.loader = false;
                    });
            }
        },
        mounted() {
            this.getSliderContent()
        }
    }
</script>

<style>
    .uk-dotnav > * {
        padding-left: 15px !important;
        transform: scale(1.6);
    }

    .slideshow {
        position: relative;
        margin-right: 100px;
        top: -30px;
        width: 283px;
        height: 283px;
    }

    .images {
        position: absolute;
        display: block;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }


    .linear-slideshow {
        z-index: 1;
        background-image: linear-gradient(90deg, #6848D2, #8764F8);
    }

    .slideshow::after {
        z-index: -1;
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background-image: linear-gradient(180deg, #A98EFF, #815FF1);
        width: 283px;
        height: 283px;
        border-radius: 9999999px;
    }


    @media only screen and (max-width: 768px){
        .slideshow {
        margin-right:auto;
        margin-left: auto;
        top: -10px;
        width: 250px;
        height: 250px;
    }
    .slideshow::after {
        width: 250px;
        height: 250px;
    }
    .images {
        top: -20px;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .uk-dotnav > * {
        padding-left: 12px !important;
        transform: scale(1.1);
    }
    .uk-dotnav{
       top: calc(100% - 20px);
       left: 45%;
    }
    }
    @media only screen and (max-width: 600px){
        .slideshow {
        top: -20px;
        width: 200px;
        height: 200px;
    }
    .slideshow::after {
        width: 200px;
        height: 200px;
    }
    .images {
        width: 150px;
        height: auto;
        top: -10px;
    }
    .uk-dotnav > * {
        transform: scale(0.9);
    }
    }
    @media only screen and (max-width: 480px){
    .slideshow {
        display: none;
    }
    .slideshow::after {
        display: none;
    }
    .images {
        display: none;
    }
    }

</style>