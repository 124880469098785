<template>
    <div class="max-w-[1364px] mx-auto mt-[29px]">
        <div class="flex flex-row items-center">
            <div>
                <a class="text-[15px] font-medium text-[#989898] td
                            hover:text-[#989898]" href="javascript:void(0);"
                >
                    {{ $t('system.bosh_sahifa') }}
                </a>
            </div>
            <div class="flex items-center mx-[13px]">
                <img alt="" src="@/assets/chevron-right-gray.svg">
            </div>
            <div>
                <div class="text-[15px] text-[#000] font-medium td">
                    {{ $t('system.buyurtma_berish') }}
                </div>
            </div>
        </div>
        <div class="my-[45px] text-[#000] font-medium text-[25px]">
            {{ $t('system.buyurtmani_shakllantirish') }}
        </div>

        <form>
            <HeaderCheck class="header-underline-date">
                <template #name>
                    {{ $t('system.your_inform') }}
                </template>
            </HeaderCheck>

            <div class="uk-flex mt-[41.4px]">
                <div>
                    <div class="text-[#000] text-[15px] font-medium">
                        {{ $t('system.phone') }}
                    </div>
                    <div class="w-[402px] p-[18px] h-[56px] flex flex-row
                     border border-[#C3B2FF] rounded-[8px] mt-[17px] relative"
                         :class="{'border-red-600' : form.errors.phone, 'border border-[#C3B2FF]' : !form.errors.phone}"
                    >
                        <div class="text-[#000] text-[16px] font-medium">
                            (+998)
                        </div>
                        <div class="ml-[3px]">
                            <input ref="phone"
                                   v-model="tempPhone"
                                   v-mask="'(00)000-00-00'"
                                   placeholder="-- --- -- --"
                                   type="text"
                                   class="focus:outline-0 w-full text-[#000] font-medium mr-[180px]"
                            >
                        </div>
                    </div>
                </div>
                <div class="ml-[35px]">
                    <div class="text-[#000] text-[15px] font-medium">
                        {{ 'F.I.SH.' }}
                    </div>
                    <div class="w-[612px] p-[18px] h-[56px] flex flex-row
                     border  rounded-[8px] mt-[17px] relative"
                         :class="{'border-red-600' : form.errors.first_name, 'border border-[#C3B2FF]' : !form.errors.first_name}"
                    >
                        <div class="ml-[3px]">
                            <input ref="first_name"
                                   v-model="form.first_name"
                                   type="text"
                                   class=" focus:outline-0 w-full text-[#000] font-medium mr-[180px]"
                            >
                        </div>
                    </div>
                </div>
            </div>

            <HeaderCheck class="header-underline-location mt-[60.4px] mb-[41.4px]">
                <template #name>
                    {{ $t('system.location_a') }}
                </template>
            </HeaderCheck>
            <div class="uk-flex mb-[41.2px]">
                <div>
                    <div class="text-[#000] text-[15px] font-medium mb-[17px]">
                        {{ $t('system.viloyat') }}
                    </div>
                    <div>
                        <div
                                :class="[openRegion ? 'relative uk-inline opened' : 'relative uk-inline', form.errors.region ? 'error' : '']">
                            <Select2 :options="regions"
                                     @focusin="openRegion=true"
                                     @focusout="openRegion=false"
                                     @select="regionSelectEvent($event)"
                            >
                            </Select2>
                            <img src="@/assets/chevron-down.svg" alt="" class="absolute top-[24px] right-[24px]">
                        </div>
                    </div>
                </div>
                <div class="ml-[35px]">
                    <div class="text-[#000] text-[15px] font-medium mb-[17px]">
                        {{ $t('system.tuman') }}
                    </div>
                    <div>
                        <div
                                :class="[openCity ? 'relative uk-inline opened' : 'relative uk-inline', form.errors.city ? 'error' : '']">
                            <Select2 :options="areas"
                                     @select="areaSelectEvent($event)"
                                     @focusin="openCity=true"
                                     @focusout="openCity=false"
                                     v-model="form.receiverCityId"
                            >
                            </Select2>
                            <img src="@/assets/chevron-down.svg" alt="" class="absolute top-[24px] right-[24px]">
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-flex mb-[50px]">
                <div>
                    <div class="text-[#000] text-[15px] font-medium">
                        {{ $t('system.manzil') }}
                    </div>
                    <input type="text"
                           class="px-[18px] input py-[18px] bg-[#fff] w-[839px] h-[56px]
                          focus:outline-0 border border-[#C3B2FF] rounded-[8px]
                          mt-[17px] text-[#000] "
                           :placeholder="$t('system.placeholder_1')"
                           v-model="form.address"
                           ref="address"
                           :class="{'border-red-600' : form.errors.address, 'border border-[#C3B2FF]' : !form.errors.address}"
                    >
                </div>

                <!-- <button type="submit" @click="submit"
                        class="bg-[#6848D2] rounded-[7px] text-[16px] text-[#fff] ml-[35px] mt-[39px]
                        cursor-pointer px-[10px] py-[18px] max-h-[56px] flex items-center"
                >
                    подтвердить
                </button> -->
            </div>

            <div class="uk-flex mb-[50px]">
                <div style="margin-top: 15px; color: black;" class="flex" v-if="formPostal.receiverCityId != null">
                    <input type="checkbox" id="checkbox" class="" v-model="checked">
                    <div class="block">
                        <label for="checkbox"
                               class="text-black font-semibold text-[16px] hover:cursor-pointer self-center block">
                            Bts pochta xizmatidan foydalanish
                        </label>
                    </div>
                </div>
            </div>


            <div class="mb-[60.8px] ">
                <HeaderCheck class="header-underline-order mb-[48.7px]">
                    <template #name>
                        {{ $t('system.your_order') }}
                    </template>
                </HeaderCheck>
                <div class="uk-grid order-bottom-line">
                    <div class="uk-navbar-left">
                        <div class="text-[#000000] text-[18px] font-semibold leading-[22px]">
                            {{ $t('system.mahsulot') }}
                        </div>
                    </div>
                    <div class="uk-navbar-right">
                        <ul class="flex space-x-36 text-[#000000] text-[18px] font-semibold leading-[22px] mr-[75px]">
                            <li>
                                {{ $t('system.count') }}
                            </li>
                            <li>
                                {{ $t('system.price') }}
                            </li>
                            <!-- <li>
                                Доставка
                            </li> -->
                            <li>
                                {{ $t('system.all_of_them') }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-for="(product, index) in items" :key="index" class="flex mt-[36px] order-bottom-line-a">
                    <div class="bg-[#F7F7FF] rounded-[8px] py-[13px] px-[25px] mr-[26px]">
                        <img v-if="product.images.length > 0" :src="product.images[0].image_url"
                             class="w-[55px] h-[74px]"
                             :alt="product.name" :title="product.name">
                        <img v-else class="w-[55px] h-[74px]" src="@/assets/atlas.png" alt="">
                    </div>
                    <div class="text-[#000000] text-[16px] font-medium leading-[19px] self-center w-[319px] mr-[301px] order-bottom-line-b">
                        {{product.name}}
                    </div>
                    <div class="self-center mr-[111px] order-bottom-line-c text-[14px] font-semibold">
                        <count-btn-v2 :product="product" :key="product.id" @sayToParent="itemListener"/>
                    </div>
                    <div class="text-[#000000] text-[20px] font-medium self-center mr-20 w-32 order-bottom-line-d">
                        {{ number_format(product.selling_price, ' ', ' ') }} {{ $t('system.som') }}
                    </div>
                    <div class="text-[#6C4BD8] text-[20px] font-semibold self-center">
                        {{ number_format (product.count * product.selling_price, ' ', ' ') }} {{ $t('system.som') }}
                    </div>
                </div>

                <div class="justify-content-right">
                    <div class="flex justify-end mt-[40px] self-center order-right-line">
                        <div class="text-[17px] w-16 font-bold text-[#000000] mr-[120px] order-right-little-line">
                            {{ $t('system.all_of_them') }} :
                        </div>
                        <div class="text-[#6C4BD8] text-[20px] font-semibold w-[145px] block">
                            {{number_format (all_product_price, ' ', ' ')}} {{ $t('system.som') }}
                        </div>
                    </div>
                    <div v-if="checked" class="flex justify-end mt-[40px] self-center order-right-line">
                        <div class="text-[17px] font-bold text-[#000000] w-60 mr-[120px] order-right-little-line-a">
                            {{'BTS Pochta (Вес: ' + all_weight + ' kg) :'}}
                        </div>
                        <div class="text-[#6C4BD8] text-[20px] font-semibold w-[145px] block">
                            {{number_format (bts_postal_price, ' ', ' ') + ' ' + $t('system.som')}}
                        </div>
                    </div>
                    <div v-if="checked" class="flex justify-end mt-[40px] self-center order-right-line">
                        <div class="text-[17px] font-bold text-[#000000] mr-[120px] order-right-little-line-b">
                            {{ $t('system.all_of_them') + '(Xizmatlar bilan)' }} :
                        </div>
                        <div class="text-[#6C4BD8] text-[20px] font-semibold w-[145px] block">
                            {{number_format (all_price, ' ', ' ')}} {{ $t('system.som') }}
                        </div>
                    </div>
                </div>

                <br/>
                <div class="" style="margin-top: 50px">
                    <button @click="confirmOrder" type="button" :disabled="isDisabled"
                            class="uk-navbar-right px-[20px] py-[12px] rounded-[7px] bg-[#6C4BD8] text-[#FFFFFF] text-[16px] font-medium leading-[19px] transition-style">
                        <img v-if="isLoading" class="img_lading"
                             :src="'https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif'"/>
                        <span class="">{{ 'Buyurtmani tasdiqlash' }}</span>
                    </button>
                </div>
            </div>

            <vue-basic-alert
                    :duration="300"
                    :closeIn="3000"
                    ref="alert"/>
        </form>
    </div>
</template>

<script>

    import HeaderCheck from "@/components/blocks/HeaderCheck";
    import Select2 from 'vue3-select2-component';
    import '@vuepic/vue-datepicker/dist/main.css'
    import axios from "axios";
    import {apiUrls} from "@/utils/const/apiUrls";
    import CountBtnV2 from "@/components/blocks/CountBtnV2";
    import VueBasicAlert from 'vue-basic-alert'
    import mixin from "@/boot/boot-mixin";

    export default {
        name: "OrderConfirmation",
        components: {CountBtnV2, HeaderCheck, VueBasicAlert, Select2},
        mixins: [mixin],
        data() {
            return {
                status: '',
                isLoading: false,
                isDisabled: false,
                isStyled: false,

                checked: false,
                likes: 1,
                items: [],
                all_price: 0,
                all_product_price: 0,
                bts_postal_price: 0,
                all_weight: 0,
                order_id: '',
                regions: [],
                areas: [],
                openCity: false,
                openRegion: false,
                date: null,
                tempPhone: String(this.$store.getters.getUser.phone).substring(String(this.$store.getters.getUser.phone).length - 9),
                beanForm: {
                    products: [],
                    request: {
                        weight: 0,
                        receiver: '',
                        receiverAddress: '',
                        receiverCityId: null,
                        receiverPhone: ''
                    },
                    is_with_shipping: 0,
                    shipping_sum: 0
                },
                form: {
                    phone: '',
                    receiverCityId: null,
                    first_name: String(this.$store.getters.getUser.fio ? this.$store.getters.getUser.fio : ''),
                    last_name: '',
                    region: '',
                    city: '',
                    address: '',
                    errors: {
                        phone: false,
                        first_name: false,
                        last_name: false,
                        region: false,
                        city: false,
                        address: false
                    }
                },
                formPostal: {
                    senderCityId: 192, //M.Ulug`bek tumani kartografiya
                    receiverCityId: null,
                    weight: 0,
                    volume: 0,
                    senderDate: '',
                    senderDelivery: 1,
                    receiverDelivery: 1,
                },
                validation: {
                    phone: {
                        min: 13,
                        max: 13,
                        type: "number",
                        nullable: false
                    },
                    first_name: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    last_name: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    region: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    city: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    address: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    }
                },
            }
        },
        computed: {
            storeUser() {
                return this.$store.getters.getUser;
            }
        },
        methods: {

            regionSelectEvent({id, text}) {
                this.getAreasByRegionsId(Number(id))
            },
            areaSelectEvent({id, text}) {
                this.formPostal.receiverCityId = Number(id)
                this.beanForm.request.receiverCityId = Number(id)
                this.btsPostPrice()
            },

            getAreasByRegionsId(val) {
                axios.get(apiUrls.BTS_AREAS + "?" + this.setParams({regions_id: val}))
                    .then(res => {
                        this.areas = res.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },

            getRegions() {
                axios.get(apiUrls.BTS_REGIONS)
                    .then(res => {
                        this.regions = res.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },

            confirmOrder() {

                if (Object.keys(this.storeUser).length === 0) {
                    this.showWarningInfo('Iltimos avval OneId orqali avtorizatsiyadan o`ting');
                    return
                }
                this.form.phone = this.tempPhone;

                if (this.form.phone.length > this.validation.phone.max || this.form.phone.length < this.validation.phone.min) {
                    this.showErrorInfo('Telefon raqami kiritilishi shart!');
                    this.form.errors.phone = true;
                    return;
                }
                if (typeof this.form.first_name === this.validation.first_name.type && (this.form.first_name.length > this.validation.first_name.max || this.form.first_name.length <= this.validation.first_name.min)) {
                    this.showErrorInfo('F.I.O kiritilishi shart!');
                    this.form.errors.first_name = true;
                    return;
                }
                this.form.errors.first_name = false;
                if (this.form.receiverCityId == null) {
                    this.showErrorInfo('Viloyat va tuman kiritilishi shart!');
                    this.form.errors.city = true;
                    return;
                }
                this.form.errors.city = false;
                if (typeof this.form.address === this.validation.address.type && (this.form.address.length > this.validation.address.max || this.form.address.length <= this.validation.address.min)) {
                    this.showErrorInfo('Manzil kiritilishi shart!');
                    this.form.errors.address = true;
                    return;
                }
                this.form.errors.address = false;

                // return this.form.errors.phone && this.form.errors.first_name && this.form.errors.city && this.form.errors.address;

                this.beanForm.shipping_sum = this.bts_postal_price
                this.beanForm.products = this.getStoreProduct()
                this.beanForm.request.weight = this.all_weight
                this.beanForm.request.receiver = this.form.first_name
                this.beanForm.request.receiverAddress = this.form.address
                this.beanForm.request.receiverPhone = this.form.phone

                this.isLoading = true
                this.isDisabled = true

                axios.post(apiUrls.AGREEMENTS_POST + "?" + this.setParams({'users_id': this.storeUser.id}), this.beanForm)
                    .then(res => {
                        this.isLoading = false
                        this.isDisabled = false
                        this.showInfo('Sizning buyurtmangiz qabul qilindi');
                        this.$store.commit('clearStoreItems');

                        setTimeout(() => {
                            this.$router.push("/cabinet")
                        }, 2000)

                    }).catch(err => {
                    this.isLoading = false
                    this.isDisabled = false
                    let error = err;
                    this.showErrorInfo('Buyurtmani tasdiqlashda xatolik!');
                })
            },

            itemListener() {
                this.allPrice()
                this.btsPostPrice()
            },

            allPrice() {
                let data = this.getStoreProduct();
                let sum_weight = 0;
                this.all_product_price = 0
                this.all_weight = 0
                for (let i = 0; i < data.length; i++) {
                    this.all_product_price += data[i].selling_price * data[i].count
                    sum_weight += data[i].weight * data[i].count
                }
                this.formPostal.weight = sum_weight.toFixed(2);
                this.all_weight = sum_weight.toFixed(2)
                this.all_price = this.all_product_price
            },

            btsPostPrice() {
                let current_date = new Date();
                let completeDate = current_date.getFullYear() + '-' + (current_date.getMonth() + 1 < 10 ? '0' : '') + (current_date.getMonth() + 1) + '-' + (current_date.getDate() + 1 < 10 ? '0' : '') + current_date.getDate();
                if (this.formPostal.receiverCityId == null) {
                    this.showErrorInfo('Iltimos tumaningizni tanlang');
                    return
                }
                this.formPostal.senderDate = completeDate
                this.bts_postal_price = 0
                axios.post(apiUrls.BTS + '/calculate', this.formPostal)
                    .then(res => {
                        this.bts_postal_price = res.data.summaryPrice;
                        this.all_price = res.data.summaryPrice + this.all_product_price;
                    }).catch(err => {

                })
            },

            getStoreProduct() {
                return this.$store.getters.getSIProducts
            },

            tableFilterQuery(filter) {
                if (!filter) return '';
                let queryArray = [];
                Object.keys(filter).map((objectKey, index) => {
                    const value = filter[objectKey];
                    if (value === undefined || value === null || value.length === 0)
                        return;
                    queryArray.push(objectKey + '=' + value);
                });

                if (queryArray.length !== 0)
                    return '?' + queryArray.join('&');
                else return ""
            },

        },
        watch: {
            checked: function (val) {
                if (val) {
                    this.beanForm.is_with_shipping = 1
                    this.btsPostPrice()
                } else {
                    this.beanForm.is_with_shipping = 0
                    this.all_price = this.all_product_price
                    this.bts_postal_price = 0
                }
            },
            tempPhone: function (val) {
                if (val.length === 13) this.form.errors.phone = false;
            }
        },
        mounted() {
            this.getRegions()
            this.allPrice()
            this.items = this.getStoreProduct()
            this.checked = false
            this.beanForm.is_with_shipping = 0
        }
    }
</script>

<style>
    input[type=checkbox] {
        border: 0;
        width: 50px;
        height: 1.2em;
    }
</style>

<style>

    .img_lading {
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 10px;
    }

    .button {
        position: relative;
        padding: 8px 16px;
        background: #009579;
        border: none;
        outline: none;
        border-radius: 2px;
        cursor: pointer;
    }

    .button:active {
        background: #007a63;
    }

    .button__text {
        font: bold 20px "Quicksand", san-serif;
        color: #ffffff;
        transition: all 0.2s;
    }

    .button--loading .button__text {
        visibility: hidden;
        opacity: 0;
    }

    .button--loading::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }


    .select2-container {
        width: 402px !important;
    }

    .select2-selection {
        height: 56px !important;
        display: flex !important;
        align-items: center !important;
        padding: 18px !important;
        border: 1px solid #C3B2FF !important;
        border-radius: 8px !important;
    }

    .error .select2-selection {
        border: 1px solid #f00 !important
    }

    .opened .select2-selection {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .select2-selection__arrow {
        display: none !important;
    }

    .select2-dropdown {
        border: 1px solid #C3B2FF !important;
        border-top: 0px !important;
        padding: 12px 12px 12px 14.5px !important;
    }

    .select2-search__field {
        background-color: #FAF9FE !important;
        border: 1px solid #C3B2FF !important;
        border-radius: 8px !important;
        height: 42px !important;
    }

    .select2-search__field:focus {
        outline: none !important;
        color: #000 !important;
    }

    .select2-selection__rendered {
        color: #000 !important;
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .select2-results__option {
        color: #000 !important;
        font-size: 15px !important;
        font-weight: 500 !important;
        padding: 13px 0px 12px 15.5px !important;
        border-bottom: 1px solid #E7E2F9 !important;
        background-color: #fff !important;
    }

    .select2-results__option:hover {
        background-color: #FAF9FE !important;
        transition: all .3s !important;
    }

    .select2-results__options {
        padding-right: 4px;
    }

    .select2-results__options::-webkit-scrollbar {
        width: 5px;
    }

    .select2-results__options::-webkit-scrollbar-track {
        box-shadow: none;
        height: 32px !important;
    }

    .select2-results__options::-webkit-scrollbar-thumb {
        height: 32px !important;
        background-color: #6949D3;
        border-radius: 99999999px;
    }

    .dp__input {
        background-color: none !important;
        border-radius: 4px;
        font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        /* border: none!important; */
        border: none;
        outline: none;
        transition: border-color .2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 130%;
        font-size: 1rem;
        /* line-height: 1.5rem; */
        padding: 4px 11px;
        color: var(--dp-text-color);
        box-sizing: border-box;
        margin-left: 17%;
        margin-top: -5%;
    }

    .db__icon {
        display: none !important;
    }

    .db__icon {
        display: none !important;
    }

    .dp__input_icon {
        display: none;
    }

    .dp__cancel {
        /* display: none; */
    }

    .dp__action_row {
        /* display: none; */
    }

    .dp__calendar_header {
        display: none;
    }
</style>