<template>
    <div class="max-w-[1364px] mx-auto">
        <div class="flex flex-col mt-[45px] mb-[331px]">
            <TableBlock>
                <template #h1>
                    Дата
                </template>
                <template #h2>
                    Товар
                </template>
                <template #h3>
                    № заказа
                </template>
                <template #h4>
                    Количество
                </template>
                <template #h5>
                    Статус
                </template>
                <template #h6>
                    Цена
                </template>
            </TableBlock>
            <TableText>
                <template #t1>
                    25.04.2022
                </template>
                <template #t2>
                    Маданий мерос обьектлари
                    атласи атласи
                </template>
                <template #t3>
                    0247478
                </template>
                <template #t4>
                    1
                </template>
                <template #t5>
                    Выполнен
                </template>
                <template #t6>
                    70000 сум
                </template>
            </TableText>
            <TableText>
                <template #t1>
                    25.04.2022
                </template>
                <template #t2>
                    Ўзбекистон Республикаси
                    сиёсий-маъмурий харитаси
                </template>
                <template #t3>
                    0247478
                </template>
                <template #t4>
                    1
                </template>
                <template #t5>
                    В процессе
                </template>
                <template #t6>
                    70000 сум
                </template>
            </TableText>
            <TableText>
                <template #t1>
                    25.04.2022
                </template>
                <template #t2>
                    Ўлкашунослик атласи
                </template>
                <template #t3>
                    0247478
                </template>
                <template #t4>
                    1
                </template>
                <template #t5>
                    Выполнен
                </template>
                <template #t6>
                    70000 сум
                </template>
            </TableText>
            <TableText>
                <template #t1>
                    25.04.2022
                </template>
                <template #t2>
                    Маданий мерос обьектлари
                    атласи атласи
                </template>
                <template #t3>
                    0247478
                </template>
                <template #t4>
                    1
                </template>
                <template #t5>
                    Выполнен
                </template>
                <template #t6>
                    70000 сум
                </template>
            </TableText>
        </div>
    </div>
</template>

<script>
import TableBlock from "@/components/blocks/TableBlock";
import TableText from "@/components/blocks/TableText";
export default {
    name: "HistorySection",
    components: {TableText, TableBlock}
}
</script>

<style scoped>

</style>