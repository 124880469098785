<template>
    <div class="max-w-[1364px] mx-auto">
        <div class="flex flex-row items-center mt-[29px]">
            <div>
                <router-link to="/" class="text-[15px] font-medium text-[#989898] td
                            hover:text-[#989898]"
                >
                    {{$t('system.bosh_sahifa')}}
                </router-link>
            </div>
            <div class="flex items-center mx-[13px]">
                <img src="@/assets/chevron-right-gray.svg" alt="">
            </div>
            <div>
                <div class="text-[15px] text-[#000] font-medium td">
                    {{$t('system.history_purchase')}}
                </div>
            </div>
        </div>
        <div class="my-[45px] text-[#000] text-[25px] font-medium
                border-b border-[#E7E2F9] pb-[29.5px]"
        >
        {{$t('system.history_purchase')}}
        </div>
    </div>
</template>

<script>
export default {
    name: "HistoryHeader"
}
</script>

<style scoped>

</style>