<template>
 <div>
     <Vue3Marquee style="margin-top:2px;font-size: 18px;font-weight: bolder;" scrollamount="7">
         {{$t('system.site_is_test_mode')}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}
     </Vue3Marquee>
     <Navbar/>

     <HistoryHeader/>

     <HistorySection/>

     <Footer/>
 </div>
</template>

<script>
import Navbar from "@/components/layouts/Navbar";
import Footer from "@/components/layouts/Footer";
import HistoryHeader from "@/components/blocks/HistoryHeader";
import HistorySection from "@/components/layouts/HistorySection";
import i18n from "@/boot/i18n";
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
import {mapGetters} from "vuex";

export default {
    name: "HistoryPurchase",
    components: {HistorySection, HistoryHeader, Footer, Navbar, Vue3Marquee},
    methods: {
        ...mapGetters([
            'getLang'
        ]),
    },
    mounted() {
        i18n.global.locale = this.getLang()
    }
}
</script>

<style scoped>

</style>