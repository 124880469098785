<template>
    <div class="text-[#000] text-[25px] pb-[8.5px] pl-[10px] relative   font-semibold">
        <slot name="name"></slot>
    </div>
</template>

<script>
export default {
    name: `Heading`
}
</script>

<style scoped>

</style>