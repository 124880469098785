<template>
  <div class="">
    <Vue3Marquee style="margin-top:2px;font-size: 18px;font-weight: bolder;" scrollamount="7">
      {{$t('system.site_is_test_mode')}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}
    </Vue3Marquee>
    <Navbar/>
    <Kategoriya @category_id="refresh" @name="getName"/>
    <Novinki :category_id="category_id" :category_name="category_name"/>
    <!--        <PopularProducts :category_id="category_id"/>-->
    <Footer/>
  </div>
</template>

<script>
import Navbar from "@/components/layouts/Navbar";
import Footer from "@/components/layouts/Footer";
import Novinki from "@/components/layouts/Novinki";
// import PopularProducts from "@/components/layouts/PopularProducts";
import Kategoriya from "@/components/layouts/Kategoriya";
import i18n from "@/boot/i18n";
import {Vue3Marquee} from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
import axios from "axios";
import {apiUrls} from "@/utils/const/apiUrls";
import mixin from "@/boot/boot-mixin"
import {mapGetters} from "vuex";


export default {
  name: `Home`,
  components: {
    Kategoriya,
    // PopularProducts,
    Novinki,
    Footer,
    Navbar,
    Vue3Marquee
  },
  mixins: [mixin],
  data() {
    return {
      category_name: '',
      category_id: null,
      isClicked: 0,
      products: [],
    }
  },
  methods: {
    ...mapGetters([
      'getLang'
    ]),

    refresh(e) {
      this.category_id = e;
    },

    getName(name) {
      this.category_name = name;
    }
  },

  mounted() {
    i18n.global.locale = this.getLang()
  }
}
</script>

<style scoped>

</style>