<template>
    <div>
        <Vue3Marquee style="margin-top:2px;font-size: 18px;font-weight: bolder;" scrollamount="7">
            {{$t('system.site_is_test_mode')}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}
        </Vue3Marquee>
        <Navbar/>

        <CabinetHeader/>

        <CabinetSection/>

        <Footer/>
    </div>

    <vue-basic-alert
            :duration="300"
            :closeIn="3000"
            ref="alert"/>

</template>

<script>
import Navbar from "@/components/layouts/Navbar";
import Footer from "@/components/layouts/Footer";
import CabinetHeader from "@/components/blocks/CabinetHeader";
import CabinetSection from "@/components/blocks/CabinetSection";
import VueBasicAlert from 'vue-basic-alert'
import i18n from "@/boot/i18n";
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
import mixin from "@/boot/boot-mixin";
import {mapGetters} from "vuex";

export default {
    name: "PersonalCabinet",
    components: {CabinetSection, CabinetHeader, Footer, Navbar, Vue3Marquee, VueBasicAlert},
    mixins: [mixin],
    methods: {
        ...mapGetters([
            'getLang'
        ]),

        showMess() {
            this.showInfo('Sizning buyurtmangiz qabul qilindi');
        }
    },
    mounted() {
        i18n.global.locale = this.getLang()
    }
}
</script>

<style scoped>

</style>