<template>
    <Transition>
        <div v-if="show" class="dialog z-10" @click.stop="hideDialog">
            <div class="bg-[#fff] dialog__content" @click.stop>
                <div class="flex cursor-pointer justify-end ml-auto" @click="hideDialog">
                    <img src="@/assets/x.svg" alt="">
                </div>
                <div class="flex justify-center mt-[26px] mb-[31px] text-[#000] text-[20px] font-bold">
                    {{ $t('system.personal_cabinet') }}
                </div>
                <div class="flex flex-col px-[94px]">
                    <div class="border border-[#CABFF1] rounded-[11px]
                            flex flex-row items-center px-[14px] py-[6px]"
                         style="width: 385px!important;height: 57px!important;">
                        <div class="text-[#000] text-[16px] font-medium">
                            +998
                        </div>
                        <div class="ml-[18px]">
                            <input type="text" class="input p-[10px] focus:outline-0">
                        </div>
                        <div class="ml-auto cursor-pointer">
                            <img src="@/assets/phone.svg" alt="">
                        </div>
                    </div>
                    <div class="border border-[#CABFF1] rounded-[11px]
                           mt-[24px] flex flex-row items-center px-[14px] py-[6px]"
                         style="width: 385px!important;height: 57px!important;">
                        <div class="text-[#000] text-[16px] font-medium">
                            {{ $t('system.password') }}
                        </div>
                        <div class="ml-[18px]">
                            <input type="password" ref="paswd"
                                   class="input p-[10px] focus:outline-0"
                            >
                        </div>
                        <div class="ml-auto cursor-pointer" @click="showOrHidePassword">
                            <img src="@/assets/input-eye.svg" alt="">
                        </div>
                    </div>
                    <div class="flex justify-center my-[28px]">
                        <div class="text-[16px] italic">
                            <a href="#" class="uk-link-reset black-link">
                                {{ $t('system.parolni_unutdingizmi') }}
                            </a>
                        </div>
                    </div>
                    <router-link to="/cabinet" class="flex justify-center td">
                        <AdminBtn/>
                    </router-link>
                    <div class="flex flex-row mt-[31px] mb-[23px] justify-between">
                        <div class="border-r border-[#ECECEC]">
                            <a href="#" class="pr-[20px] td text-[16px] text-[#919191] hover:text-[#000]">
                                {{ $t('system.hisobingiz_yoqmi') }}
                            </a>
                        </div>
                        <div>
                            <a href="#" class="text-[16px] td text-[#919191] hover:text-[#000]">
                                {{ $t('system.register') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import AdminBtn from "@/components/blocks/AdminBtn";
import axios from "axios";

export default {
    name: `Modal`,
    components: {AdminBtn},
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        value: String,
        form: {
            phone: "",
            password: ""
        }
    },
    methods: {
        hideDialog() {
            this.$emit('hideModal', false);
        },
        showOrHidePassword() {
            let paswd = this.$refs.paswd;
            paswd.type = paswd.type === 'text' ? 'password' : 'text';
        },
        submit(){
            axios.post('login', this.form).then(res => {
                this.$store.dispatch("login", res.data.data)
            })
        },
        logout(){
            this.$store.dispatch("logout");
        }
    }
}
</script>

<style scoped>
.dialog {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
}

.dialog__content {
    margin: auto;
    background: white;
    border-radius: 12px;
    min-height: 50px;
    min-width: 300px;
    padding: 20px;
}

.input {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.input::placeholder {
    font-size: 16px;
    font-weight: 500;
}

.input:focus {
    border: none !important;
    outline: none !important;
}
.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

</style>