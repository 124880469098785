<template>
    <div class=" w-full bg-[#fff] z-[9999999] h-full right-0 top-0 relative">
        <div class="bg-[#6C4BD8] flex flex-row justify-between items-center
                            py-[20px]  pl-[37px] pr-[36.5px]">
            <div class="flex flex-row items-center text-[#fff]">
                <div>
                    <img src="@/assets/korzinka-icon.svg" alt="">
                </div>
                <div class="ml-[15px]">
                    {{ products.length }} {{ $t('system.dona') }}
                </div>
            </div>
            <div class="cursor-pointer">
                <img src="@/assets/close-icon.svg" class="uk-offcanvas-close" alt="">
            </div>
        </div>
        <div class="pl-[37px] pr-[36.5px] pt-[34px] bg-white">
            <div class="flex flex-col">
                <div>
                    <div class="flex flex-row items-center pb-4 pt-2 border-b border-[#E7E2F9] min-h-[164px]"
                         v-for="item in products" :key="item.id"
                    >
                        <div class="py-[18px] bg-[#F7F7FF] rounded-[7px] px-[30px]
                             max-w-[143px] max-h-[145px]
                            ">
                            <img v-if="item.images.length > 0" :src="item.images[0].image_url" :alt="item.name" :title="item.name"
                                 class="max-w-[83px] max-h-[110px]">
                            <img v-else src="@/assets/atlas.png" alt="" class="max-w-[83px] max-h-[110px]">
                        </div>
                        <div class="flex flex-col ml-[26px] relative">
                            <div class="text-[15px] text-[#000] mb-[22.6] font-medium max-w-[263px]">
                                {{ item.name }} <br/>
                                {{ $t('system.count') + ': ' + item.count }} <br/>
                                {{
                                    $t('system.price') + ': ' + number_format(item.price, ' ', ' ') + ' ' + $t('system.uz_sum')
                                }}
                            </div>
                            <div class="flex flex-row items-center mt-[22.6px]">
                                <!--                                <div>-->
                                <!--                                    <SmallCountBtn :product="item"/>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                    <div class="mb-28"/>
                </div>
            </div>
        </div>
        <div v-if="prices" class="flex flex-col fixed bottom-0 w-[505px]">
            <div class="font-bold bg-white border-t-2 text-base text-gray-800 text-right py-1 px-4">
                {{ $t('system.OrderKarzinka.shippingPrice') }} :
                <span> {{ number_format(prices?.shippingPrice, ' ', ' ') + ' ' + $t('system.uz_sum') }}</span>
            </div>
            <div class="font-bold bg-white border-t-2 text-base text-gray-800 text-right py-1 px-4">
                {{ $t('system.karzinka.totalPrice') }} :
                <span> {{ number_format(totalProductPrice, ' ', ' ') + ' ' + $t('system.uz_sum') }}</span>
            </div>
            <div>
                <a v-if="status === 2" @click="toPay"
                   id="#oformitZayafku"
                   class="py-6 px-8 cursor-pointer flex items-center text-white text-base font-medium justify-center w-full td bg-[#6C4BD8]"
                >
                    {{ $t('system.tolov') }}
                </a>
            </div>
        </div>
    </div>

    <vue-basic-alert
        :duration="300"
        :closeIn="3000"
        ref="alert"/>

</template>

<script>

import SmallCountBtn from "@/components/blocks/SmallCountBtn";
import { apiUrls } from "@/utils/const/apiUrls";
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import UIkit from "uikit/dist/js/uikit";
import mixin from "@/boot/boot-mixin";

export default {
    name: `OrderKarzinka`,
    components: { VueBasicAlert },
    mixins: [mixin],
    props: {
        prices: {
            type: Object,
            default: () => ({})
            },
        products: Array,
        id: Number,
        status: Number
    },
    data () {
        return {
            post: {},
        }
    },
    computed: {
        totalProductPrice () {
            return this.calculateTotalPrice(); // call the method for calculating total price
        },
    },
    methods: {
        toPay () {
            if (this.status === 2) {
                let offcanvas = document.getElementById('offcanvas-flip');
                UIkit.offcanvas(offcanvas).hide();
                this.$router.push({ name: 'pay', params: { agreement_id: this.id } })
            } else {
                let mess = 'To`lovni amalga oshirish tasdiqlanmagan'
                this.showErrorInfo(mess)
            }
        },
        calculateTotalPrice () {
            let total = this.prices?.shippingPrice + this.prices.bookPrice;
            return Math.round(total);
        },
        number_format (number, decimals, thousands_sep) {
            return number ? number.toFixed(decimals ? decimals : 2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (thousands_sep ? thousands_sep : ' ')) : "0"
        },
        showErrorInfo (message) {
            this.$refs.alert.showAlert(
                'error', // There are 4 types of alert: success, info, warning, error
                message, // Message of the alert
                'Error', // Header of the alert
                {
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'top right'
                } // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
            )
        }
    },
    async mounted () {
    }
}
</script>
<style>

</style>