<template>
    <div>
        <Vue3Marquee style="margin-top:2px;font-size: 18px;font-weight: bolder;" scrollamount="7">
            {{$t('system.site_is_test_mode')}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{$t('system.site_is_test_mode')}}
        </Vue3Marquee>
        <Navbar/>

<!--        <oformit-pokupku :agreement_id="agreement_id"/>-->
        <order-confirmation/>

        <Footer/>
    </div>
</template>

<script>
    import Navbar from "@/components/layouts/Navbar";
    import OformitPokupku from "@/components/layouts/OformitPokupku";
    import Footer from "@/components/layouts/Footer";
    import i18n from "@/boot/i18n";
    import { Vue3Marquee } from 'vue3-marquee'
    import 'vue3-marquee/dist/style.css'
    import OrderConfirmation from "@/components/layouts/OrderConfirmation";
    import {mapGetters} from "vuex";


    export default {
        name: `Checkout`,
        components: {OrderConfirmation, Footer,  Navbar, Vue3Marquee},
        // props: {
        //     agreement_id: Number,
        // },
        data() {
            return {
                products: this.items
            }
        },
        methods: {
            ...mapGetters([
                'getLang'
            ]),
        },
        mounted() {
            i18n.global.locale = this.getLang()
        }
    }
</script>

<style scoped>

</style>