<template>
    <div class=" w-full bg-[#fff] z-[9999999] h-full right-0 top-0 relative">
        <div class="bg-[#6C4BD8] flex flex-row justify-between items-center
                            py-[20px]  pl-[37px] pr-[36.5px]">
            <div class="flex flex-row items-center text-[#fff]">
                <div>
                    <img src="@/assets/korzinka-icon.svg" alt="">
                </div>
                <div class="ml-[15px]">
                    {{ products.length }} {{ $t('system.dona') }}
                </div>
            </div>
            <div class="cursor-pointer">
                <img src="@/assets/close-icon.svg" class="uk-offcanvas-close" alt="">
            </div>
        </div>
        <div class="pl-[37px] pr-[36.5px] pt-[34px] bg-white">
            <div class="flex flex-col">
                <div>
                    <div class="flex flex-row items-center pb-4 pt-2 border-b border-[#E7E2F9] min-h-[164px]"
                         v-for="item in products" :key="item.id"
                    >
                        <div class="py-[18px] bg-[#F7F7FF] rounded-[7px] px-[30px]
                             max-w-[143px] max-h-[145px]
                            ">
                            <img v-if="item.images.length > 0" :src="item.images[0].image_url" :alt="item.name" :title="item.name"
                                 class="max-w-[83px] max-h-[110px]">
                            <img v-else src="@/assets/atlas.png" alt="" class="max-w-[83px] max-h-[110px]">
                        </div>
                        <div class="flex flex-col ml-[26px] relative">
                            <div class="absolute top-[-5px] karzinka-close cursor-pointer"
                                 @click="removeStProduct(item)"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.602"
                                     viewBox="0 0 13.75 13.602">
                                    <g transform="translate(-0.02 0.006)">
                                        <path
                                            d="M.074,1.289C.074,2,.617,2.383,1.1,2.867c.3.3,3.819,3.786,3.914,3.928C4.918,6.939.9,10.921.456,11.366a1.412,1.412,0,0,0-.284.36A1.268,1.268,0,0,0,.02,12.3v.1a1.246,1.246,0,0,0,.355.861,1.406,1.406,0,0,0,1.1.328,1.376,1.376,0,0,0,.88-.409L5.572,9.957l.645-.645a7.842,7.842,0,0,0,.651-.665c.172.115,4.03,4.037,4.558,4.546a1.474,1.474,0,0,0,2,.024,1.438,1.438,0,0,0,.345-.786V12.2a1.377,1.377,0,0,0-.423-.848L10.406,8.412c-.06-.06-.1-.1-.161-.162s-.116-.09-.189-.16L8.748,6.767c.149-.1,4.061-4.049,4.519-4.5a1.241,1.241,0,0,0,.262-.383A1.3,1.3,0,0,0,12.454.027a1.257,1.257,0,0,0-1.068.409l-3.84,3.84a7.64,7.64,0,0,1-.651.638c-.218-.146-.984-1.02-1.3-1.281a1.788,1.788,0,0,1-.174-.149L2.363.423a1.318,1.318,0,0,0-2.289.866Z"
                                            transform="translate(0 0)" fill="currentColor" fill-rule="evenodd"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="text-[15px] text-[#000] mb-[22.6] font-medium max-w-[263px]">
                                {{ item.name }}
                            </div>
                            <div class="flex flex-row items-center mt-[22.6px]">
                                <div>
                                    <SmallCountBtn :product="item" :key="item.id"/>
                                </div>
                                <div class="text-[18px] ml-8 font-bold text-[#6C4BD8]">
                                    {{ number_format(item.selling_price, ' ', ' ') + ' ' + $t('system.uz_sum') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-28"/>
                </div>
            </div>
        </div>
        <div class="flex flex-col fixed bottom-0 w-[505px]">
            <div v-if="totalProductPrice" class="font-bold bg-white border-t-2 text-base text-gray-800 text-right py-1 px-4">
                {{ $t('system.karzinka.totalPrice') }} :
                <span> {{ number_format(totalProductPrice, ' ', ' ') + ' ' + $t('system.uz_sum') }}</span>
            </div>
            <a v-if="products.length > 0 && routeName !== 'checkout'" href="javascript:void(0)" uk-toggle @click="toCheckout"
               class="py-6 px-8 cursor-pointer flex items-center text-white text-base font-medium justify-center w-full td bg-[#6C4BD8]"
            >
                {{ $t('system.buyurtma_berish') }}
            </a>
        </div>

    </div>

</template>

<script>

import SmallCountBtn from "@/components/blocks/SmallCountBtn";
import { apiUrls } from "@/utils/const/apiUrls";
import axios from "axios";
import mixin from "@/boot/boot-mixin";
import { computed } from "vue";
import { useRoute } from "vue-router";


export default {
    name: `karzinka`,
    components: { SmallCountBtn },
    mixins: [mixin],
    props: {
        show: Number
    },
    data () {
        return {
            checked: false,
            post: {},
            products: [],
            user: {}
        }
    },
    computed: {
        totalProductPrice () {
            return this.calculateTotalPrice(); // call the method for calculating total price
        },
        storeUser () {
            return this.$store.getters.getUser;
        }
    },
    methods: {
        calculateTotalPrice () {
            let total = 0;
            for (let item of this.products) {
                total += item.count * item.selling_price;
            }
            return Math.round(total);
        },
        toCheckout () {
            this.$router.push({ name: 'checkout' })
        },

        removeStProduct (item) {
            this.$store.commit('removeStore', item)
            this.products = this.getStoreProduct()
        },
        getStoreProduct () {
            return this.$store.getters.getSIProducts
        },
        postProducts () {
            if (Object.keys(this.storeUser).length === 0) {
                let data = {};
                data["message"] = 'Iltimos avval OneId orqali avtorizatsiyadan o`ting';
                data["check"] = false;
                this.toNav(data);
                return
            }

            axios.post(apiUrls.AGREEMENTS_POST + "?" + this.setParams({ 'users_id': this.storeUser.id }), this.products)
                .then(res => {
                    this.post = res.data.data;
                    this.$store.commit('clearStoreItems');
                    let data = {};
                    data["message"] = 'Sizning buyurtmangiz qabul qilindi';
                    data["check"] = true;
                    this.toNav(data);
                }).catch(err => {
                this.getStoreProduct()
                let data = {};
                let error = err;
                data["message"] = 'Buyurtmani tasdiqlashda xatolik!';
                data["check"] = false;
                this.toNav(data);
            })
        },

        // refreshStoreItem(e) {
        //     this.products = this.getStoreProduct()
        // },

        toNav (data) {
            this.$emit("checkOrderConfirm", data);
        },
    },
    watch: {
        show: function (val) {
            this.products = this.getStoreProduct()
        },
        checked: function (val) {

        }
    },
    async mounted () {
        this.user = this.storeUser
        this.products = this.getStoreProduct()
    },
    setup () {
        const routeName = computed(() => {
            return useRoute().name
        })
        return { routeName }
    }
}
</script>
<style>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(100px);
    opacity: 0;
}

.karzinka-close {
    right: -15px;
    color: #bbb !important;
}

.karzinka-close:hover {
    color: #6C4BD8 !important;
    transition: all 0.5s;
}
</style>