<template>
    <div class="max-w-[1364px] mx-auto mt-[80px] xl:px-0 px-[40px] mb-[67px]">
        <Heading id="category-name" class="header-underline">
            <template #name>
                {{ this.category_name !== '' ? this.category_name : $t('system.mahsulot') }}
            </template>
        </Heading>

        <div class="mt-[30px] grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 flex-wrap" v-if="!loader">
            <router-link class="hover:no-underline" :to="'/singleProduct/' + String(item.id)"
                         v-for="item in products" :key="item.id">
                <Card :hasDeliver="true" :item="item">
                    <template #img>
                        <img v-if="item.images.length>0" :src="item.images[0].image_url" :alt="item.name"
                             :title="item.name"
                             class="max-h-[150px] py-[10px]">

                        <img v-else :src="item?.image_url" :alt="item.name" class="max-h-[150px] py-[10px]">
                    </template>
                    <template #title>
                        {{ item.name }}
                    </template>
                    <template #original_price>
                        {{ number_format(item.selling_price, ' ', ' ') + ' ' + $t('system.uz_sum') }}
                    </template>
                </Card>
            </router-link>
        </div>

        <div class="flex justify-center mt-6 items-center w-full" v-else>
            <div style="color: #6848D2!important;" uk-spinner="ratio: 3"></div>
        </div>

        <div class="bg-white"
             style="padding-bottom: 10px; padding-right: 10px; border-radius: 20px;">
            <div class="max-w-lg mx-auto mt-4">
                <PaginationV2 :total-pages="pagination.totalPages" :total="pagination.total"
                              :per-page="pagination.perPage"
                              :current-page="pagination.currentPage"
                              :has-more-pages="pagination.hasMorePages" @pagechanged="showMore"
                />
            </div>
        </div>
    </div>

</template>

<script>
    import Heading from "@/components/blocks/Heading";
    import Card from "@/components/blocks/Card";
    import {apiUrls} from "@/utils/const/apiUrls";
    import axios from "axios";
    import mixin from "@/boot/boot-mixin"
    import PaginationV2 from "@/components/blocks/PaginationV2.vue";

    export default {
        name: `Novinki`,
        components: {PaginationV2, Card, Heading,},
        mixins: [mixin],
        props: {
            category_id: {
                type: Number
            },
            category_name: {
                default: '',
                type: String
            }
        },
        data() {
            return {
                products: [],
                productArr: [],
                loader: false,
                count: 8,
                disabled: false,
                filter: {},
                pagination: {
                    totalPages: 0,
                    total: 0,
                    perPage: 12,
                    currentPage: 1,
                    hasMorePages: true
                },
            }
        },
        methods: {
            showMore(page) {
                this.pagination.currentPage = page;
                if (this.category_id) {
                    this.getProducts(this.category_id)
                } else {
                    this.getProducts(null)
                }
            },

            getProducts(val) {
                this.loader = true
                let filter = this.getFilters()
                axios.get(apiUrls.PRODUCTS + '/with-images' + this.optionPage(filter) + this.setParams({product_categories_id: val}))
                    .then(res => {
                        this.products = res.data.data;
                        this.pagination.total = res.data.total;
                        this.pagination.totalPages = Math.ceil(res.data.total / this.pagination.perPage)
                        this.loader = false;
                    })
                    .catch(function (error) {
                        console.log('----error => ' + error);
                        this.loader = false;
                    }).finally(() => {
                    this.loader = false
                });
            },
            getFilters() {
                let pagination = {
                    currPage: this.pagination.currentPage,
                    size: this.pagination.perPage,

                }
                let filter = this.filter;
                for (const [key, value] of Object.entries(filter)) {
                    if (value != null && value != undefined) {
                        filter[key] = value
                    }
                }
                for (const [key, value] of Object.entries(pagination)) {
                    if (value != null && value != undefined) {
                        filter[key] = value
                    }
                }
                return filter;
            },

            splitText(text) {
                let result = ""
                let a = text.split(" ")
                for (let i = 0; i < a.length; i++) {
                    result = result + (' ' + a[i])
                    if (i >= 15) {
                        result = result + ' . . .'
                        return result
                    }
                }
                return result
            },

        },
        watch: {
            category_id(val) {
                this.pagination.currentPage = 1
                this.getProducts(val)
            }

        },
        mounted() {
            this.getProducts(null)
        }
    }
</script>

<style scoped>
</style>