<template>
  <div class="mr-[20px] mb-[18px]">
    <div>
      <div class="border border-[#E0DBF9] flex flex-col rounded-[15px] h-[460.02px]">
        <div class="flex bg-[#F7F7FF] mx-[26px] mt-[26px] rounded-[15px] relative
                items-center justify-center h-[160px]"
        >
          <div class="absolute top-[-2.5px] right-[-16px] rounded-[7px]" v-if="onSale"
               :style="'background-color: ' + saleColor"
          >
            <div class="text-[13px] text-[#fff] font-medium pt-[4px] pb-[6px] px-[7px]">
              <slot name="sale_title"></slot>
            </div>
          </div>
          <slot name="img"></slot>
          <div v-if="fav" class="absolute bottom-[13.7px] right-[14px] flex items-center justify-center
                                w-[27px] h-[27px] rounded-[5px]" :class="!white ? 'bg-[#fff]' : 'bg-[#6C4BD8]'"
          >
            <img v-if="white" src="@/assets/like-icon.svg" alt="">
            <img v-else src="@/assets/like-icon-blue.svg" alt="">
          </div>
        </div>
        <div class="card-header mt-[19px] ml-[26px] h-[38px] leading-[18px] font-semibold mr-[13px]">
          <slot name="title"></slot>
        </div>
        <div class="mx-[26px] flex flex-col">
          <!--                    <div class="mt-[37.5px] mb-[26px] flex flex-row items-center">-->
          <!--                        &lt;!&ndash; TODO: function for detecting stars&ndash;&gt;-->
          <!--                        <div class="mr-[11px]">-->
          <!--                            <img src="@/assets/star-blue.svg" alt="">-->
          <!--                        </div>-->
          <!--                        <div class="mr-[11px]">-->
          <!--                            <img src="@/assets/star-blue.svg" alt="">-->
          <!--                        </div>-->
          <!--                        <div class="mr-[11px]">-->
          <!--                            <img src="@/assets/star-blue.svg" alt="">-->
          <!--                        </div>-->
          <!--                        <div class="mr-[11px]">-->
          <!--                            <img src="@/assets/star-gray.svg" alt="">-->
          <!--                        </div>-->
          <!--                        <div class="mr-[34px]">-->
          <!--                            <img src="@/assets/star-gray.svg" alt="">-->
          <!--                        </div>-->
          <!--                        <div class="text-[11px] font-medium text-[#959595]">-->
          <!--                            <slot name="comments"></slot>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <div class="mb-[18px] text-[14px] font-medium  leading-[18px] card-text" v-if="hasDeliver">
            <slot name="deliver"></slot>
          </div>
          <div class="flex flex-row justify-between" v-if="onSale">
            <div class="card-sale-price">
              <slot name="sale_price"></slot>
            </div>
            <div class="card-price">
              <del>
                <slot name="original_price"></slot>
              </del>
            </div>
          </div>
          <div class="flex flex-row" v-if="!onSale">
            <div class="card-sale-price">
              <slot name="original_price"></slot>
            </div>
          </div>
        </div>
        <div class="mt-[45px] mx-[22px] flex-wrap mb-[28px] flex flex-row justify-between">
          <div>
            <SaleButton @click="addSIPro(item)">
              <template #title>
                {{ $t('system.add_bucket') }}
              </template>
              <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="22.648" height="19.163"
                     viewBox="0 0 22.648 19.163">
                  <g transform="translate(0)">
                    <g transform="translate(0 0)">
                      <g transform="translate(0 0)">
                        <path
                            d="M5.431,5.323H17.162c.966,0,1.961-.024,2.923,0,1.265.029.823,1.167.688,1.841-.2,1-.515,2.769-.736,3.669a.791.791,0,0,1-.864.62H6.628a4.394,4.394,0,0,0-.132-.778l-.152-.758c-.176-.881-.831-3.945-.914-4.593ZM-.123.631A.761.761,0,0,0,.688,1.5c.495,0,.993,0,1.488,0,.535,0,.833.1.987.5.107.279,2.2,10.991,2.511,12.524a3.408,3.408,0,0,0,.363,1.169,2.278,2.278,0,0,0,1.883,1.133h11.97a.777.777,0,0,0,.259-1.456,19.575,19.575,0,0,0-3.036-.076H8.83c-1.6,0-1.439-.1-1.7-1.409a6.337,6.337,0,0,1-.164-.89H19.412a2.2,2.2,0,0,0,1.641-.8,3.375,3.375,0,0,0,.669-1.821c.154-.77.8-3.63.8-4.369a2.283,2.283,0,0,0-2.2-2.2H5.1a3.252,3.252,0,0,0-.108-.658c-.041-.2-.088-.45-.135-.679A5.21,5.21,0,0,0,4.506,1.22,2.268,2.268,0,0,0,2.558-.04H.547a.788.788,0,0,0-.67.67Z"
                            transform="translate(0.123 0.04)" fill="currentColor"
                        />
                        <path
                            d="M13760.9,13761.591c0,.529.322.862.669.862h10.152c.349,0,.67-.333.67-.862a.788.788,0,0,0-.67-.671h-10.152A.787.787,0,0,0,13760.9,13761.591Z"
                            transform="translate(-13753.239 -13753.259)" fill="currentColor"
                        />
                        <path
                            d="M15137,31650.691a.772.772,0,1,0,1.532,0c0-.348-.331-.672-.862-.672A.787.787,0,0,0,15137,31650.691Z"
                            transform="translate(-15128.573 -31632.398)" fill="currentColor"
                        />
                        <path
                            d="M31650.1,31650.691a.773.773,0,1,0,1.533,0c0-.348-.332-.672-.861-.672A.789.789,0,0,0,31650.1,31650.691Z"
                            transform="translate(-31632.477 -31632.398)" fill="currentColor"
                            fill-rule="evenodd"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </template>
            </SaleButton>
          </div>
          <div style="margin-top: 10px">
            <SaleButton>
              <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="31.53" height="18.424"
                     viewBox="0 0 31.53 18.424">
                  <g transform="translate(0.001)">
                    <g transform="translate(-0.001 0)">
                      <path
                          d="M254.768,17.068h-.431a1.9,1.9,0,0,1-1.724-1.724,1.771,1.771,0,0,1,.6-1.618,1.944,1.944,0,1,1,1.556,3.342Zm12.255-1.909a1.956,1.956,0,1,1,.567,1.342A2.353,2.353,0,0,1,267.023,15.159Zm5.666-.677H272.2a2.936,2.936,0,0,0-.859-1.666c-2.317-2.317-5.506-.086-5.545,1.666H262.65a5.693,5.693,0,0,1,.222-1.133l1.81-8.043,4.734.008a5.052,5.052,0,0,1,1.792.548,5.458,5.458,0,0,1,.693.477,4.43,4.43,0,0,1,1.472,2.1c.451,1.338-.034,2.477-.3,4.028-.117.687-.237,1.374-.386,2.015ZM248.61.687a.685.685,0,0,0,.383.6,2.532,2.532,0,0,0,.971.076H264.19L261.419,13.62a6.051,6.051,0,0,0-.185.862h-3.449a3.3,3.3,0,0,0-6.466,0c-.554,0-1.109,0-1.663,0-.339,0-1.046-.064-1.046.678,0,.524.507.677.983.68.574,0,1.152,0,1.727,0a3.14,3.14,0,0,0,.93,1.657,3.287,3.287,0,0,0,5.142-.7,3.5,3.5,0,0,0,.328-.78c.06-.134-.008-.18.19-.18h7.883a3.247,3.247,0,0,0,3.018,2.587,3.151,3.151,0,0,0,2.35-.791,3.19,3.19,0,0,0,1.037-1.8c.751,0,1.486.121,1.7-.455a7.4,7.4,0,0,0,.183-.926c.065-.363.11-.584.175-.933a35.982,35.982,0,0,0,.651-3.967,5.644,5.644,0,0,0-.96-2.92,5.882,5.882,0,0,0-2.61-2.193,5.635,5.635,0,0,0-2.465-.491c-1.293,0-2.587,0-3.88,0,.062-.129.739-3.126.739-3.325a.712.712,0,0,0-.677-.616H249.287a.7.7,0,0,0-.677.677Z"
                          transform="translate(-243.375 -0.01)" fill="currentColor"
                          fill-rule="evenodd"/>
                      <path
                          d="M-.01,500.766c0,.481.27.739.8.739H8.365c1.133,0,1-1.355.246-1.355H.606a.609.609,0,0,0-.616.616Z"
                          transform="translate(0.01 -489.62)" fill="currentColor"
                          fill-rule="evenodd"/>
                      <path
                          d="M61.42,313.638c0,.475.446.677.924.677H68.5c1.133,0,1-1.355.246-1.355H62.036a.655.655,0,0,0-.616.677Z"
                          transform="translate(-60.127 -306.371)" fill="currentColor"
                          fill-rule="evenodd"/>
                      <path
                          d="M125.091,127.125h4.927c1.133,0,1-1.355.246-1.355h-5.419C124.088,125.77,123.959,127.125,125.091,127.125Z"
                          transform="translate(-121.643 -123.122)" fill="currentColor"
                          fill-rule="evenodd"/>
                    </g>
                  </g>
                </svg>
              </template>
              <template #title>
                {{ $t('system.deliver') }}
              </template>
            </SaleButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <vue-basic-alert
      :duration="300"
      :closeIn="3000"
      ref="alert"/>

</template>

<script>
import SaleButton from "@/components/blocks/SaleButton";
import {mapMutations} from "vuex";
import mixin from "@/boot/boot-mixin";
import VueBasicAlert from 'vue-basic-alert'

export default {
  name: `Card`,
  components: {SaleButton, VueBasicAlert},
  mixins: [mixin],
  props: {
    onSale: Boolean,
    hasDeliver: Boolean,
    fav: Boolean,
    white: Boolean,
    blue: Boolean,
    _saleColor: String,
    item: Object
  },
  data() {
    return {
      saleColor: this._saleColor ?? '#14BF30'
    }
  },
  methods: {
    ...mapMutations([
      'addStore'
    ]),

    addSIPro(item) {
      if (item.balance === 0) {
        this.showWarningInfo('Hozirda ushbu mahsulotdan omborda qolmagan!');
        return;
      }

      this.addStore(item);
      this.$store.getters.getSIProducts
        this.showInfo('Mahsulot savatchaga qo`shildi!');
      // $store.dispatch('addStore', {...item, count: 1})
    }
  }
}
</script>

<style scoped>
.card-header {
  line-height: 1.2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>