<template>


        <a href="#"
           class="bg-[#6848D2] text-[#fff] font-medium flex items-center justify-center
           rounded-[11px] hover:no-underline hover:text-[#fff] uk-box-shadow-medium"
            style="transition: all .6s; width: 185px!important;height: 57px!important;"
        >

                {{$t('system.enter')}}

        </a>

</template>

<script>
export default {
    name: "AdminBtn"
}
</script>

<style scoped>

</style>