<template>
    <div uk-filter="target: .izb-filter;animation: delayed-fade">
        <div class="my-[45px] pb-[29.5px] border-b border-[#E7E2F9] uk-flex uk-flex-between">
            <div class="text-[#000] font-medium text-[25px] uk-active"
                 uk-filter-control=".not-deleted"
            >
                {{ $t('system.sevimli') }}
            </div>
            <router-link to="" class="cursor-pointer w-[133px] h-[43px] flex items-center justify-center
                    text-[16px] text-[#fff] font-medium rounded-[7px] bg-[#6C4BD8] white-link "
                         uk-filter-control=".deleted"
            >
                {{ $t('system.toza') }}
            </router-link>
        </div>



        <div class="izb-filter" v-if="!loader">
            <div class="not-deleted grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 flex-wrap gap-4 mb-[74.7px]" >
                <Card :fav="true" :white="true" :hasDeliver="true" :onSale="true" v-for="item in products" :key="item.id">
                    <template #img>
                        <img :src="item?.image_url" alt="" class="max-h-[150px] py-[10px]">

                    </template>
                    <template #sale_title>
                        новинка
                    </template>
                    <template #title>
                        {{item.name}}
                    </template>
                    <template #comments>
                        0 отзывов
                    </template>
                    <template #sale_price>
                        5 750 сўм
                    </template>
                    <template #original_price>
                        6 000 сўм
                    </template>
                    <template #deliver>
                        {{ $t('system.yetkazib_beriladi') }}
                    </template>
                </Card>
<!--                <Card :fav="true" :white="true" :hasDeliver="true" :onSale="true" :_saleColor="'#E3AE00'">-->
<!--                    <template #img>-->
<!--                        <router-link to="/about">-->
<!--                            <img src="@/assets/UzAtlas.png" alt="" class="max-h-[150px] py-[10px]">-->
<!--                        </router-link>-->

<!--                    </template>-->
<!--                    <template #sale_title>-->
<!--                        скидка-->
<!--                    </template>-->
<!--                    <template #title>-->
<!--                        O'zbekiston milliy Atlasi-->
<!--                    </template>-->
<!--                    <template #comments>-->
<!--                        0 отзывов-->
<!--                    </template>-->
<!--                    <template #sale_price>-->
<!--                        5 750 сўм-->
<!--                    </template>-->
<!--                    <template #original_price>-->
<!--                        6 000 сўм-->
<!--                    </template>-->
<!--                    <template #deliver>-->
<!--                        Ўзбекистон Республикаси бўйлаб-->
<!--                        етказиб берилади-->
<!--                    </template>-->
<!--                </Card>-->
            </div>
            <div class="deleted">
                <DeletedInfo/>
            </div>
        </div>
        <div class="flex justify-center my-6 items-center w-full" v-else>
            <div style="color: #6848D2!important;" uk-spinner="ratio: 3"></div>
        </div>
    </div>
</template>

<script>
import Card from "@/components/blocks/Card";
import DeletedInfo from "@/components/blocks/DeletedInfo";
import axios from "axios";
import {apiUrls} from "@/utils/const/apiUrls";

export default {
    name: "IzbranniyLayout",
    components: {DeletedInfo, Card},
    data() {
        return {
            products: {},
            loader: true
        }
    },
    methods:{
        getProducts() {
            axios.get(apiUrls.PRODUCT_CATEGORY)
                .then(res => {
                    this.products = res.data.data;
                    this.loader = false;
                })
                .catch(function (error) {
                    console.log(error);
                    this.loader = false;
                });
        }
    },
    mounted() {
        this.getProducts()
    }

}
</script>

<style scoped>

</style>