<template>
  <div class="max-w-[1364px] mx-auto mt-[70.2px] xl:px-0 px-[40px] mb-[49.3px]">
    <Heading class="products-header-underline mb-[26px]" style="padding-left: 0px!important;">
      <template #name>
        {{ $t('system.korib_chiqishingizni_maslahat') }}
      </template>
    </Heading>
  </div>
  <div uk-slider="sets: true" class="mb-[109px]">
    <div class="uk-position-relative uk-visible-toggle " tabindex="-1">
      <div class="max-w-[1364px] mx-auto overflow-hidden">
        <div class="mt-[30px] grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 flex-wrap" v-if="!loader">
          <router-link class="hover:no-underline" v-for="item in products" :key="item.id" :to="'/singleProduct/' + String(item.id)">
            <Card class="" :hasDeliver="true" :item="item">
              <template #img>
                <img v-if="item.images.length>0" :src="item.images[0].image_url" :alt="item.name" :title="item.name"
                     class="max-h-[150px] py-[10px]">
                <img v-else :src="item?.image_url" alt="" class="max-h-[150px] py-[10px]">
              </template>
              <!--                            <template #sale_title>-->
              <!--                                новинка-->
              <!--                            </template>-->
              <template #title>
                {{ item.name }}
              </template>
              <!--                            <template #deliver>-->
              <!--                                {{item?.description}}-->
              <!--                            </template>-->
              <template #original_price>
                {{ number_format(item.selling_price, ' ', ' ') + ' ' + $t('system.uz_sum') }}
              </template>
            </Card>
          </router-link>
        </div>
        <div class="flex justify-center mt-6 items-center w-full" v-else>
          <div style="color: #6848D2!important;" uk-spinner="ratio: 3"></div>
        </div>
      </div>
    </div>
    <ul class="uk-slider-nav slider-dotnav uk-dotnav uk-flex-center" style="margin-top: 41.3px!important;"></ul>
  </div>
</template>

<script>
import Heading from "@/components/blocks/Heading";
import Card from "@/components/blocks/Card";
import {apiUrls} from "@/utils/const/apiUrls";
import axios from "axios";
import mixin from "@/boot/boot-mixin";
import UIkit from "uikit";

export default {
  name: `AboutSlider`,
  components: {Card, Heading},
  mixins: [mixin],
  data() {
    return {
      products: {},
      loader: true
    }
  },
  methods: {
    singleItem(id) {
      this.$router.replace({path: '/singleProduct/' + String(id)})
          .then(function () {
            location.reload()
          })

    },

    getProducts() {
      axios.get(apiUrls.PRODUCTS + '/with-images')
          .then(res => {
            this.products = res.data.data;
            this.loader = false;
          })
          .catch(function (error) {
            console.log(error);
            this.loader = false;
          });
    }
  },
  mounted() {
    this.getProducts()
  }
}
</script>

<style>
.slider-dotnav > * > * {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid #6C4BD8 !important;
  background-color: #fff !important;
  margin-right: 7px !important;
}

.slider-dotnav > .uk-active > * {
  background-color: #6C4BD8 !important;
}

.test.slider-dotnav > * > * {
  width: 13px !important;
  height: 13px !important;
  border: 1px solid #6C4BD8 !important;
  background-color: #ffffff !important;
}

.test.slider-dotnav > .uk-active > * {
  background-color: #6C4BD8 !important;
}

.slider-dotnav > * {
  padding-left: 7px !important;
}

.uk-position-center-left {
  left: 190px !important;
  border: 1px solid #6C4BD8;
  border-radius: 13px;
  cursor: pointer;
}

.uk-position-center-right {
  right: 190px !important;
  border: 1px solid #6C4BD8;
  border-radius: 13px;
  cursor: pointer;
}

.chevron-arrow {
  color: #6949D3 !important;
}

.chevron-arrow:hover {
  color: #fff !important;
}

.uk-position-center-left:hover {
  transition: all .8s !important;
  background-color: #6949D3;
  border: 1px solid #6C4BD8;
  border-radius: 13px;
  cursor: pointer;
}

.uk-position-center-right:hover {
  transition: all .8s !important;
  background-color: #6949D3;
  border: 1px solid #6C4BD8;
  border-radius: 13px;
  cursor: pointer;
}
</style>