<template>
    <div>
        <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 flex-wrap gap-4 mb-[74.7px]" v-if="!loader">
            <Card :fav="true" :hasDeliver="true" :onSale="true" v-for="item in products" :key="item.id">
                <template #img>
                    <img :src="item?.image_url" alt="" class="max-h-[150px] py-[10px]">
                </template>
                <template #sale_title>
                    новинка
                </template>
                <template #title>
                    {{ item.name }}
                </template>
                <template #comments>
                    0 отзывов
                </template>
                <template #sale_price>
                    5 750 сўм
                </template>
                <template #original_price>
                    6 000 сўм
                </template>
                <template #deliver>
                    {{ item?.description }}
                </template>
            </Card>
            <!--        <Card :fav="true" :hasDeliver="true" :onSale="true" :_saleColor="'#E3AE00'">-->
            <!--            <template #img>-->
            <!--                <router-link to="/about">-->
            <!--                    <img src="@/assets/UzAtlas.png" alt="" class="max-h-[150px] py-[10px]">-->
            <!--                </router-link>-->
            <!--            </template>-->
            <!--            <template #sale_title>-->
            <!--                скидка-->
            <!--            </template>-->
            <!--            <template #title>-->
            <!--                O'zbekiston milliy Atlasi-->
            <!--            </template>-->
            <!--            <template #comments>-->
            <!--                0 отзывов-->
            <!--            </template>-->
            <!--            <template #sale_price>-->
            <!--                5 750 сўм-->
            <!--            </template>-->
            <!--            <template #original_price>-->
            <!--                6 000 сўм-->
            <!--            </template>-->
            <!--            <template #deliver>-->
            <!--                Ўзбекистон Республикаси бўйлаб-->
            <!--                етказиб берилади-->
            <!--            </template>-->
            <!--        </Card>-->
            <!--        <Card :fav="true" :hasDeliver="true" :onSale="true" >-->
            <!--            <template #img>-->
            <!--                <img src="@/assets/kok-atlas.png" alt="" class="max-h-[150px] py-[10px]">-->

            <!--            </template>-->
            <!--            <template #sale_title>-->
            <!--                скидка-->
            <!--            </template>-->
            <!--            <template #title>-->
            <!--                Тарих фанидан Атлас ва-->
            <!--                ёзувсиз хариталар-->
            <!--            </template>-->
            <!--            <template #comments>-->
            <!--                0 отзывов-->
            <!--            </template>-->
            <!--            <template #sale_price>-->
            <!--                5 750 сўм-->
            <!--            </template>-->
            <!--            <template #original_price>-->
            <!--                6 000 сўм-->
            <!--            </template>-->
            <!--            <template #deliver>-->
            <!--                Ўзбекистон Республикаси бўйлаб-->
            <!--                етказиб берилади-->
            <!--            </template>-->
            <!--        </Card>-->
            <!--        <Card :fav="true" :hasDeliver="true" :onSale="true" >-->
            <!--            <template #img>-->
            <!--                <img src="@/assets/atlas.png" alt="" class="max-h-[150px] py-[10px]">-->

            <!--            </template>-->
            <!--            <template #sale_title>-->
            <!--                скидка-->
            <!--            </template>-->
            <!--            <template #title>-->
            <!--                География фанидан Атлас ва-->
            <!--                ёзувсиз хариталар 5-9 синфлар-->
            <!--                учун-->
            <!--            </template>-->
            <!--            <template #comments>-->
            <!--                0 отзывов-->
            <!--            </template>-->
            <!--            <template #sale_price>-->
            <!--                5 750 сўм-->
            <!--            </template>-->
            <!--            <template #original_price>-->
            <!--                6 000 сўм-->
            <!--            </template>-->
            <!--            <template #deliver>-->
            <!--                Ўзбекистон Республикаси бўйлаб-->
            <!--                етказиб берилади-->
            <!--            </template>-->
            <!--        </Card>-->
        </div>
        <div class="flex justify-center my-6 items-center w-full" v-else>
            <div style="color: #6848D2!important;" uk-spinner="ratio: 3"></div>
        </div>
    </div>
</template>

<script>
import Card from "@/components/blocks/Card";
import axios from "axios";
import {apiUrls} from "@/utils/const/apiUrls";
export default {
    name: `NedovnaCard`,
    components: {Card},
    data() {
        return {
            products: {},
            loader: true
        }
    },
    methods:{
        getProducts() {
            axios.get(apiUrls.PRODUCTS)
                .then(res => {
                    this.products = res.data.data;
                    this.loader = false;
                })
                .catch(function (error) {
                    console.log(error);
                    this.loader = false;
                });
        }
    },
    mounted() {
        this.getProducts()
    }
}
</script>

<style scoped>

</style>