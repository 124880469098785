import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../components/page/Home'
import AboutProducts from "@/components/page/AboutProducts";
import Checkout from "@/components/page/Checkout";
import Pay from "@/components/page/Pay";
import Selected from "@/components/page/Selected";
import HistoryPurchase from "@/components/page/HistoryPurchase";
import PersonalCabinet from "@/components/page/PersonalCabinet";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: AboutProducts
  },
  {
    path: '/singleProduct/:id',
    name: 'singleProduct',
    component: AboutProducts
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
    props: true
  },
  {
    path: '/pay/:agreement_id',
    name: 'pay',
    component: Pay,
    props: true
  },
  {
    path: '/selected',
    name: 'selected',
    component: Selected
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryPurchase
  },
  {
    path: '/cabinet',
    name: 'cabinet',
    component: PersonalCabinet,
    // beforeEnter: (to, from) => {
    //   console.log(to, from);
    //   console.log('auth', store.state.auth)
    //   return store.state.auth === {};
    // }
  }
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.path !== from.path) {
      // Scroll to top when navigating to a different page
      return { top: 0 };
    }
  },
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
