import uz from './uz'
import ru from './ru'


const ext_ru=Object.assign(ru);
const ext_uz=Object.assign(uz);

export default {
  'uz': ext_uz,
  'ru': ext_ru,
}
