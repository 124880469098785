<template>
<div class="flex flex-col justify-center items-center max-w-[422px] mx-auto
            mt-[91px] mb-[177px]"
>
    <div>
        <img src="@/assets/box.svg" alt="">
    </div>
    <div class="text-[20px] text-[#000] font-semibold my-[21px]">
        {{$t('system.sevimlilar_royxati')}}
    </div>
    <div class="mb-[26px] uk-text-center">
        {{$t('system.sevimliga_qoshish')}}
    </div>
    <router-link to="/" class="border border-[#E7E2F9] rounded-[7px] bg-[#F7F7FF] td
                w-[133px] h-[43px] flex items-center justify-center cursor-pointer
                text-[#000] hover:border-[#6C4BD8] hover:bg-[#6C4BD8] hover:text-[#fff]"
         style="transition: all .5s!important;"
    >
        {{$t('system.bosh_sahifa')}}
    </router-link>
</div>
</template>

<script>
export default {
    name: "deleted-info"
}
</script>

<style scoped>

</style>