<template>
    <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
        <div @click="close(0)" class="absolute w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div class="absolute max-h-full min-w-[400px]">
                <div class="container bg-white overflow-hidden md:rounded-lg">
                    <div @click.stop class="p-8 leading-none items-center font-medium text-sm bg-gray-100 border-b select-none">
                        <slot></slot>
                        <div class="flex justify-between px-8">
                            <div @click="close(1)" class="bg-green-500 py-2 px-6 rounded-lg text-white font-bold text-sm cursor-pointer">
                                {{$t('system.yes')}}
                            </div>
                            <div @click="close(0)" class="bg-red-500 py-2 px-6 rounded-lg text-white font-bold text-sm cursor-pointer">
                                {{$t('system.no')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PopUpModal",
    emits: ['modalAction'],
    methods: {
        close (num) {
            this.$emit('modalAction', num)
        }
    }
};
</script>